import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
// components
import Iconify from "../ui/iconify";
import { baseUrl } from "../requestUrl";
import { setUserSession, setPremission } from "../utils/common";
import NotificationContext from "../../store/notification-context";
import { ASSIGN_PERMISSION_API, GET, POST } from "../../api/config";
import { useGet, usePost } from "../../hooks/useFetch";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const notificationCtx = useContext(NotificationContext);

  const [showPassword, setShowPassword] = useState(false);

  const { data: permissionData, refresh: getPermissionData } = useGet(
    ASSIGN_PERMISSION_API[GET]
  );

  useEffect(() => {
    if (permissionData) {
      console.info(permissionData);
      setPremission(permissionData.data || []);

      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  const handleClick = () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    axios
      .post(`${baseUrl}/user/login`, {
        email: email,
        password: password,
        forAdmin: true,
      })
      .then((response) => {
        setUserSession(response.data.data.token, response.data.data.user);
        //setUserSession(response.data.data.token, response.data.data.user);
        const adminRoleId = response.data.data.user.roleId; //"6520f3e7d8b1d6ec36a6c356";
        getPermissionData({ url: `?roleId=${adminRoleId}` });
        //navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        notificationCtx.showNotification({
          title: "Invalid username or password",
          status: "error",
        });
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" inputRef={emailRef} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          inputRef={passwordRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 2 }}
      >
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Login
      </Button>
    </>
  );
}
