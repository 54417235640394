export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const setUserSession = (token, user, permissions = []) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("isMaster", user.role == "Master");
  sessionStorage.setItem("isToken", "yes");
};

export const setPremission = (permissions = []) => {
  sessionStorage.setItem("permissions", JSON.stringify(permissions));
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("isMaster");
  sessionStorage.removeItem("isToken");
  sessionStorage.removeItem("permissions");
  sessionStorage.removeItem("current_module_id");
};

export const getPermissions = () => {
  const permissionsStr = sessionStorage.getItem("permissions");
  if (permissionsStr) return JSON.parse(permissionsStr);
  else return null;
};

export const isMaster = () => {
  return sessionStorage.getItem("isMaster") || null;
};

export const hasPermission = (slug) => {
  const currentSlug = slug.toLowerCase();
  const permissions = getPermissions();
  const isMasterUser = isMaster() == true ? true : false;
  const permissionDataExists = permissions.filter((itm) => {
    //console.log("/dashboard" + itm.basePath + " == " + menu.url);
    return "/dashboard" + itm.basePath == currentSlug;
  });
  console.log("current permission : ", permissionDataExists[0]);
  sessionStorage.setItem("current_module_id", permissionDataExists[0].moduleid);
  return currentSlug != "dashboard" &&
    !isMasterUser &&
    permissionDataExists.length == 0
    ? false
    : permissionDataExists[0];
};

// trim, rtrim, ltrim
export const trim = (str, chr) => {
  var rgxtrim = !chr
    ? new RegExp("^\\s+|\\s+$", "g")
    : new RegExp("^" + chr + "+|" + chr + "+$", "g");
  return str.replace(rgxtrim, "");
};
export const rtrim = (str, chr) => {
  var rgxtrim = !chr ? new RegExp("\\s+$") : new RegExp(chr + "+$");
  return str.replace(rgxtrim, "");
};
export const ltrim = (str, chr) => {
  var rgxtrim = !chr ? new RegExp("^\\s+") : new RegExp("^" + chr + "+");
  return str.replace(rgxtrim, "");
};
