import React, { useState, useEffect, useContext } from "react";

import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  Tooltip
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";

import axios from "../../utils/axios";
import CustomizedDialogs from "../ui/modal";
import { useGet, useDelete } from "../../hooks/useFetch";
import { COLOR_API, GET, POST, PUT, DELETE } from "../../api/config";
import NotificationContext from "../../store/notification-context";
import ImagePicker from "../ui/image-picker";
import { appendImage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import InfoIcon from '@mui/icons-material/Info';

function Color() {
  const [nValue, setNValue] = useState("");
  const [colors, setColors] = useState([]);
  const [uniqueColorCategory, setUniqueColorCategory] = useState([]);
  const [action, setAction] = useState("view");
  const [image, setImage] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);
  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(COLOR_API[GET], {
    apiCall: "onload",
  });
  const { refresh: delData } = useDelete(COLOR_API[DELETE]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setColors(getData.data || []);
    }
  }, [getData]);

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "colorName",
      headerName: "Color Name",
      width: 150,
      sortable: true,
    },
    {
      field: "colorCode",
      headerName: "Color Code",
      width: 150,
      sortable: true,
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      sortable: true,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 400,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (colors.length > 0) {
      setUniqueColorCategory([
        ...new Map(colors.map((color) => [color["name"], color])).values(),
      ]);
    }
  }, [colors]);

  const nameHandleChange = (event) => {
    setNValue(event.target.value);
  };

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Color";
    } else if (action === "add") {
      return "Add Color";
    } else {
      return "Delete Color";
    }
  };

  const onSubmit = (data) => {
    if (action === "add") {
      console.log(data.image, "data.image");
      const file = data.image[0];
      const formData = new FormData();
      formData.append("image", file);
      formData.append("name", nValue);
      formData.append("colorName", data.colorName);
      formData.append("colorCode", data.colorCode);
      axios({
        async: true,
        url: COLOR_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        setModal(false);
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    } else {
      const formData = new FormData();
      let file;
      if (typeof data.image === "object" && data.image.length > 0) {
        file = data.image[0];
        formData.append("image", file);
      }
      formData.append("name", nValue);
      formData.append("colorName", data.colorName);
      formData.append("colorCode", data.colorCode);
      formData.append("_id", rowData._id);
      axios({
        async: true,
        url: COLOR_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        setModal(false);
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    }
  };

  const editRowData = (params) => {
    const { name, colorName, colorCode, image } = params.row;
    console.log(image, "image");
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setNValue(name);
    setValue("colorName", colorName);
    setValue("colorCode", colorCode);
    setValue("image", image);
    setImage(image);
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  const changeColorCode = (e) => {
    setValue('colorCode', e.target.value.toUpperCase());
  };

  const changeColorName = (e) => {
    const inputValue = e.target.value;
    setValue('colorName', inputValue.charAt(0).toUpperCase() + inputValue.slice(1));
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Material Colors"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Color
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={colors}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={125}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={() => setModal(false)}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-color-category">
                      Select Color Category
                    </InputLabel>
                    <Select
                      labelId="select-color-category"
                      id="select-color-category"
                      value={nValue}
                      label="name"
                      onChange={nameHandleChange}
                    >
                      {uniqueColorCategory.map((colorC) => (
                        <MenuItem key={colorC.name} value={colorC.name}>
                          {colorC.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Color Name"
                    placeholder="Enter Color Name"
                    inputProps={{
                      onChange: changeColorName
                    }}
                    {...register("colorName", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                  {errors.colorName && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Color Code"
                    inputProps={{
                      onChange: changeColorCode,
                      maxLength: 7,
                      defaultValue: "#"
                    }}
                    placeholder="Please enter HEX code"
                    {...register("colorCode", {
                      required: true,
                      maxLength: 7,
                    })}
                  />
                  {errors.colorCode && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 1200×1406 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default Color;
