import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  RadioGroup,
  FormLabel,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomizedDialogs from "../ui/modal";
import { useGet, usePost, usePut, useDelete } from "../../hooks/useFetch";
import { Module_API, GET, POST, PUT, DELETE } from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";

function RoleModule() {
  const [modules, setModules] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);
  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: modulesData, refresh: refreshModules } = useGet(
    Module_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { refresh: postData } = usePost(Module_API[POST]);
  const { refresh: putData } = usePut(Module_API[PUT]);
  const { refresh: delData } = useDelete(Module_API[DELETE]);

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (modulesData) {
      setModules(modulesData.data || []);
    }
  }, [modulesData]);

  const editRowData = (params) => {
    console.log(params.row);
    const { name, basePath, isActive } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("name", name);
    setValue("basePath", basePath);
    setValue("status", isActive.toString());
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "basePath", headerName: "Base Path", width: 300 },
    {
      field: "isActive",
      headerName: "Status",
      width: 300,
      sortable: true,
      valueGetter: (params) =>
        `${params?.row?.isActive ? "Active" : "In Active"}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const closeModal = () => {
    setModal(false);
  };

  const onSubmit = (data) => {
    if (action === "add") {
      postData(
        {
          data: {
            name: data.name,
            basePath: data.basePath,
            isActive: data.status,
          },
        },
        { refreshModules, closeModal }
      );
    } else {
      data = {
        name: data.name,
        //basePath: data.basePath,
        isActive: data.status,
        _id: rowData._id,
      };
      putData({ data }, { refreshModules, closeModal });
    }
  };

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Moddule";
    } else if (action === "add") {
      return "Add Moddule";
    } else {
      return "Delete Moddule";
    }
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };
    delData({ data }, { refreshModules, closeModal });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Module List"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Module
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={modules}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Module"
                    placeholder="Module Name"
                    {...register("name", { required: true, maxLength: 20 })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Base Path"
                    placeholder="Module Name"
                    {...register("basePath", { required: true, maxLength: 20 })}
                  />
                  {errors.basePath && (
                    <span className="error-text">
                      This field is required or value length &gt; 20 characters.
                    </span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Status
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <label>
                        <div>
                          <input
                            type="radio"
                            value="true"
                            checked={true}
                            {...register("status")}
                          />
                          <span>Active</span>
                        </div>
                      </label>
                      <label>
                        <div>
                          <input
                            type="radio"
                            value="false"
                            {...register("status")}
                          />
                          <span>InActive</span>
                        </div>
                      </label>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default RoleModule;
