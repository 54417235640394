import React, { useState, useEffect } from "react";
import { baseUrl } from "../requestUrl/index";
import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "../Cards/loader";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from "@mui/material";


function ContactUserList() {
    const [id, setid] = useState([]);

    const navigate = useNavigate();

    const getContactUserList = async () => {

        const token = sessionStorage.getItem("token");

        await axios({
            method: "get",
            url: `${baseUrl}/admin/userlist`,
            headers: { Authorization: `jwt ${token}` },
        }).then((response) => {
            // console.log(response.data.data.result, "resssssssssss")
            setid(response.data.data.result)
            if (response.status === 401) {
                navigate(`/`)
            }
        }).catch(err => {
            if (err.response.status === 401) {
                navigate(`/`)
            }
        })
    };

    // useEffect(() => {
    //     getContactUserList();
    // }, []);


    if (id && id.length > 0) {
        return (
            <>
                <div id="wrapper" width="100%">
                    <div className="divmaldito">
                        <div>
                            <Card style={{ backgroundColor: "red", borderRadius: 0.5, width: "80%",position:"relative" }}><h2>Contact User List</h2></Card>
                            <Card sx={{ minWidth: 275 }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {id.map(user => (
                                            <tr key={user.id}>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.role}</td>
                                                <td>{(!user.status)?"Inactive":"Active"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (<div id="wrapper" width="100%">
            <div className="divmaldito">
                <div>
                    No Record Found
                </div>
            </div></div>
        )
    }
}

export default ContactUserList;