import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Select,
  FormControlLabel,
  MenuItem,
  Card,
  FormGroup,
  Checkbox,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  FormLabel,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useGet, usePost } from "../../hooks/useFetch";
import {
  ASSIGN_PERMISSION_API,
  PERMISSION_API,
  Role_API,
  Module_API,
  GET,
  POST,
} from "../../api/config";
import CustomizedDialogs from "../ui/modal";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";

function AssignPermission() {
  const {
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]);
  const [assignPermissions, setAssignPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [initPermission, setInitPermission] = useState(false);
  const [rValue, setRValue] = useState("");
  const [reValue, setREValue] = useState("");
  const [pValue, setPValue] = useState("");
  const [peValue, setPEValue] = useState("");
  const [allCbx, setAllCbx] = useState([]);
  const [readCbx, setReadCbx] = useState([]);
  const [createCbx, setCreateCbx] = useState([]);
  const [updateCbx, setUpdateCbx] = useState([]);
  const [deleteCbx, setDeleteCbx] = useState([]);
  //const [modal, setModal] = useState(false);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    ASSIGN_PERMISSION_API[GET]
  );
  const { refresh: postMultiAssignData } = usePost(ASSIGN_PERMISSION_API[POST]);
  const { data: getRoleData } = useGet(Role_API[GET], {
    apiCall: "onload",
  });
  const { data: getModuleData } = useGet(Module_API[GET], {
    apiCall: "onload",
  });

  const { data: getAllPermissionData } = useGet(PERMISSION_API[GET], {
    apiCall: "onload",
  });
  const { refresh: postData } = usePost(PERMISSION_API[POST]);

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      //console.info(getData);
      if (getData.data.length == 0) {
        setInitPermission(true);
      } else {
        setInitPermission(false);
      }
      setAssignPermissions(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    //console.log(assignPermissions);
    const createPermissionValues = {};
    const readPermissionValues = {};
    const updatePermissionValues = {};
    const deletePermissionValues = {};
    const allCbxPermissionValues = {};

    console.log("initPermission : ", initPermission);

    if (initPermission) {
      modules.map((module, rId) => {
        let rowId = module._id;
        createPermissionValues[rowId] = true;
        readPermissionValues[rowId] = true;
        updatePermissionValues[rowId] = true;
        deletePermissionValues[rowId] = true;
        allCbxPermissionValues[rowId] = true;
      });
    } else {
      assignPermissions.map((permission) => {
        let rowId = permission.moduleid;

        createPermissionValues[rowId] = permission.create;
        readPermissionValues[rowId] = permission.read;
        updatePermissionValues[rowId] = permission.update;
        deletePermissionValues[rowId] = permission.delete;

        if (
          createPermissionValues[rowId] == true &&
          readPermissionValues[rowId] == true &&
          updatePermissionValues[rowId] == true &&
          deletePermissionValues[rowId] == true
        ) {
          allCbxPermissionValues[rowId] = true;
        }
      });
    }

    /* set permissions */
    setAllCbx(allCbxPermissionValues);
    setCreateCbx(createPermissionValues);
    setReadCbx(readPermissionValues);
    setUpdateCbx(updatePermissionValues);
    setDeleteCbx(deletePermissionValues);
  }, [assignPermissions]);

  useEffect(() => {
    if (getRoleData) {
      setRoles(getRoleData.data.filter((item) => item.name != "Master") || []);
    }
  }, [getRoleData]);

  useEffect(() => {
    if (getModuleData) {
      setModules(getModuleData.data || []);
    }
  }, [getModuleData]);

  useEffect(() => {
    if (getAllPermissionData) {
      //console.log(getAllPermissionData.data);
      const p = Object.keys(getAllPermissionData.data);
      setPermissions(p || []);
    }
  }, [getAllPermissionData]);

  const roleHandleChange = (event) => {
    setRValue(event.target.value);

    /*const { read, create, update, delete: deleteD } = selcetedPermission;
    setCreateCbx(create);
    setReadCbx(read);
    setUpdateCbx(update);
    setDeleteCbx(deleteD);*/
  };

  const roleEHandleChange = (event) => {
    setREValue(event.target.value);
  };

  const permissionHandleChange = (event) => {
    setPValue(event.target.value);
  };

  const permissionEHandleChange = (event) => {
    setPEValue(event.target.value);
  };

  /*const closeModal = () => {
    setModal(false);
    reset();
    setRValue("");
    setPValue("");
    setCreateCbx(false);
    setReadCbx(false);
    setUpdateCbx(false);
    setDeleteCbx(false);
  };*/

  useEffect(() => {
    if (rValue) {
      refreshGetData({ url: `?roleId=${rValue}` });
      // fetch(`/permission/getassigned?roleId=${rValue}`).then((response) => {
      //   setAssignPermissions(response.data);
      // });
    }
  }, [rValue]);

  useEffect(() => {
    if (peValue) {
    }
  }, [peValue]);

  /*useEffect(() => {
    if (pValue) {
      const selcetedPermission = assignPermissions.find(
        (permission) => permission._id === pValue
      );
      console.log(selcetedPermission);
      const { read, create, update, delete: deleteD } = selcetedPermission;
      setCreateCbx(create);
      setReadCbx(read);
      setUpdateCbx(update);
      setDeleteCbx(deleteD);
    }
  }, [pValue]);*/

  /*const onSubmit = (pData) => {
    const data = {
      roleId: reValue,
      name: peValue,
      basePath: "/" + peValue,
    };
    if (pData.createCbx) {
      data.create = true;
    } else {
      data.create = false;
    }
    if (pData.readCbx) {
      data.read = true;
    } else {
      data.read = false;
    }
    if (pData.updateCbx) {
      data.update = true;
    } else {
      data.update = false;
    }
    if (pData.deleteCbx) {
      data.delete = true;
    } else {
      data.delete = false;
    }

    postData({ data }, { refreshGetData, closeModal });
  };*/

  const savePermission = async (pData) => {
    //console.log(pData);
    //return false;
    /*let data = {
      roleId: rValue,
      permissions: [
        {
          moduleid: "",
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      ],
    };*/

    let permissions = modules.map((module, rId) => {
      let rowId = module._id;
      let create = false,
        read = false,
        update = false,
        remove = false;

      if (pData.createCbx[rowId]) {
        create = true;
      } else {
        create = false;
      }
      if (pData.readCbx[rowId]) {
        read = true;
      } else {
        read = false;
      }
      if (pData.updateCbx[rowId]) {
        update = true;
      } else {
        update = false;
      }
      if (pData.deleteCbx[rowId]) {
        remove = true;
      } else {
        remove = false;
      }

      return {
        moduleid: module._id,
        create,
        read,
        update,
        delete: remove,
      };
    });

    let data = {
      roleId: rValue,
      permissions,
    };
    //console.log(data);

    await postMultiAssignData(
      { data },
      {
        refreshGetData: function () {
          refreshGetData({ url: `?roleId=${rValue}` });
        },
      }
    );
  };

  const ifAllActionsChecked = (rowId) => {
    if (
      createCbx[rowId] == true &&
      readCbx[rowId] == true &&
      updateCbx[rowId] == true &&
      deleteCbx[rowId] == true
    ) {
      let allCbxArr = allCbx;
      allCbxArr[rowId] = true;

      setAllCbx(allCbxArr);
    } else {
      let allCbxArr = allCbx;
      allCbxArr[rowId] = false;

      setAllCbx(allCbxArr);
    }
  };

  /*useEffect(() => {
    console.log(deleteCbx);
  }, [deleteCbx]);*/

  return (
    <div>
      <form onSubmit={handleSubmit(savePermission)}>
        <Grid container spacing={6} style={{ marginTop: "0" }}>
          {/* <Grid item xs={12}> */}
            {/* <Card> */}
              <CardHeader
                // title="Permssions"
                titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
                /*action={
                  {
                    <Button
                    variant="contained"
                    onClick={() => {
                      setModal(true);
                      reset();
                    }}
                  >
                    Assign Permission To Role
                  </Button>
                  }
                }*/
              />
            {/* </Card> */}
          {/* </Grid> */}
          <Grid item xs={12} md={12} style={{paddingTop: "0"}}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h5">Manage Permissions</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-role">Select Role</InputLabel>
                    <Select
                      labelId="select-role"
                      id="select-role"
                      value={rValue}
                      label="role"
                      onChange={roleHandleChange}
                      sx={{ width: "80%" }}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role._id} value={role._id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/*<Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-permission">
                    Select Permission
                  </InputLabel>
                  <Select
                    labelId="select-permission"
                    id="select-permission"
                    placeholder="Select Permission"
                    value={pValue}
                    label="permission"
                    onChange={permissionHandleChange}
                    sx={{ width: "80%" }}
                  >
                    {assignPermissions.map((permission) => (
                      <MenuItem key={permission._id} value={permission._id}>
                        {permission.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                    </Grid>*/}

                {modules.map((module, rId) => {
                  let rowId = module._id;

                  setValue(`createCbx[${rowId}]`, createCbx[rowId] ?? false);
                  setValue(`readCbx[${rowId}]`, readCbx[rowId] ?? false);
                  setValue(`updateCbx[${rowId}]`, updateCbx[rowId] ?? false);
                  setValue(`deleteCbx[${rowId}]`, deleteCbx[rowId] ?? false);

                  //console.log("in list ", createCbx);
                  // console.log("rowId : ", rowId);
                  // console.log(createCbx[rowId]);
                  if (
                    createCbx[rowId] == true &&
                    readCbx[rowId] == true &&
                    updateCbx[rowId] == true &&
                    deleteCbx[rowId] == true
                  ) {
                    setValue(`allCbx[${rowId}]`, true);
                  }

                  return (
                    <Grid key={rowId} item xs={12} md={3}>
                      <Box component="fieldset">
                        <legend>
                          <b>{module.name.toUpperCase()}</b>
                        </legend>
                        <FormGroup>
                          {/* <FormLabel component="legend">
                            Assigned Permissions
                          </FormLabel> */}
                          <FormControlLabel
                            control={<Checkbox />}
                            label="All"
                            onChange={(e) => {
                              let val = e.target.checked ? true : false;

                              //setValue(`allCbx[${rowId}]`, val);
                              let allCbxArr = allCbx;
                              allCbxArr[rowId] = allCbxArr[rowId]
                                ? !allCbxArr[rowId]
                                : true;

                              setAllCbx({ ...allCbxArr });

                              setValue(`createCbx[${rowId}]`, val);
                              let createCbxArr = createCbx;
                              createCbxArr[rowId] = val;

                              setCreateCbx({ ...createCbxArr });

                              setValue(`readCbx[${rowId}]`, val);
                              let readCbxArr = readCbx;
                              readCbxArr[rowId] = val;

                              setReadCbx({ ...readCbxArr });

                              setValue(`updateCbx[${rowId}]`, val);
                              let updateCbxArr = updateCbx;
                              updateCbxArr[rowId] = val;

                              setUpdateCbx({ ...updateCbxArr });

                              setValue(`deleteCbx[${rowId}]`, val);
                              let deleteCbxArr = deleteCbx;
                              deleteCbxArr[rowId] = val;

                              setDeleteCbx({ ...deleteCbxArr });
                            }}
                            checked={allCbx[rowId] ?? false}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Create"
                            onChange={(e) => {
                              let val = e.target.checked ? true : false;
                              //console.log("Create ", val);
                              //setValue(`createCbx[${rowId}]`, val);
                              let createCbxArr = createCbx;
                              createCbxArr[rowId] = createCbxArr[rowId]
                                ? !createCbxArr[rowId]
                                : true;

                              setCreateCbx({ ...createCbxArr });

                              if (val == true) {
                                setValue(`readCbx[${rowId}]`, val);
                                let readCbxArr = readCbx;
                                readCbxArr[rowId] = true;

                                setReadCbx({ ...readCbxArr });
                              } else {
                                setValue(`allCbx[${rowId}]`, false);
                                let allCbxArr = allCbx;
                                allCbxArr[rowId] = false;

                                setAllCbx({ ...allCbxArr });
                              }

                              ifAllActionsChecked(rowId);
                            }}
                            checked={createCbx[rowId] ?? false}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Read"
                            onChange={(e) => {
                              let val = e.target.checked ? true : false;
                              //setValue(`readCbx[${rowId}]`, val);
                              let readCbxArr = readCbx;
                              readCbxArr[rowId] = readCbxArr[rowId]
                                ? !readCbxArr[rowId]
                                : true;

                              setReadCbx({ ...readCbxArr });

                              if (val == false) {
                                setValue(`allCbx[${rowId}]`, val);
                                let allCbxArr = allCbx;
                                allCbxArr[rowId] = val;

                                setAllCbx({ ...allCbxArr });

                                setValue(`createCbx[${rowId}]`, val);
                                let createCbxArr = createCbx;
                                createCbxArr[rowId] = val;
                                setCreateCbx({ ...createCbxArr });

                                setValue(`updateCbx[${rowId}]`, val);
                                let updateCbxArr = updateCbx;
                                updateCbxArr[rowId] = val;
                                setUpdateCbx({ ...updateCbxArr });

                                setValue(`deleteCbx[${rowId}]`, val);
                                let deleteCbxArr = deleteCbx;
                                deleteCbxArr[rowId] = val;
                                setDeleteCbx({ ...deleteCbxArr });
                              }

                              ifAllActionsChecked(rowId);
                            }}
                            checked={readCbx[rowId] ?? false}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Update"
                            onChange={(e) => {
                              let val = e.target.checked ? true : false;
                              //setValue(`updateCbx[${rowId}]`, val);
                              let updateCbxArr = updateCbx;
                              updateCbxArr[rowId] = updateCbxArr[rowId]
                                ? !updateCbxArr[rowId]
                                : true;
                              setUpdateCbx({ ...updateCbxArr });

                              if (val == true) {
                                setValue(`readCbx[${rowId}]`, val);
                                let readCbxArr = readCbx;
                                readCbxArr[rowId] = true;

                                setReadCbx({ ...readCbxArr });
                              } else {
                                setValue(`allCbx[${rowId}]`, false);
                                let allCbxArr = allCbx;
                                allCbxArr[rowId] = false;

                                setAllCbx({ ...allCbxArr });
                              }

                              ifAllActionsChecked(rowId);
                            }}
                            checked={updateCbx[rowId] ?? false}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Delete"
                            onChange={(e) => {
                              let val = e.target.checked ? true : false;
                              //setValue(`deleteCbx[${rowId}]`, val);
                              let deleteCbxArr = deleteCbx;
                              deleteCbxArr[rowId] = deleteCbxArr[rowId]
                                ? !deleteCbxArr[rowId]
                                : true;
                              setDeleteCbx({ ...deleteCbxArr });

                              if (val == true) {
                                setValue(`readCbx[${rowId}]`, val);
                                let readCbxArr = readCbx;
                                readCbxArr[rowId] = true;

                                setReadCbx({ ...readCbxArr });
                              } else {
                                setValue(`allCbx[${rowId}]`, false);
                                let allCbxArr = allCbx;
                                allCbxArr[rowId] = false;

                                setAllCbx({ ...allCbxArr });
                              }

                              ifAllActionsChecked(rowId);
                            }}
                            checked={deleteCbx[rowId] ?? false}
                          />
                        </FormGroup>
                      </Box>
                    </Grid>
                  );
                })}

                {/*<Grid item xs={12}>
                <FormGroup>
                  <FormLabel component="legend">Assigned Permissions</FormLabel>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Create"
                    onChange={(e) => {
                      setValue("createCbx", e.target.checked ? true : false);
                      setCreateCbx(!createCbx);
                    }}
                    checked={createCbx}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Read"
                    onChange={(e) => {
                      setValue("readCbx", e.target.checked ? true : false);
                      setReadCbx(!readCbx);
                    }}
                    checked={readCbx}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Update"
                    onChange={(e) => {
                      setValue("updateCbx", e.target.checked ? true : false);
                      setUpdateCbx(!updateCbx);
                    }}
                    checked={updateCbx}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Delete"
                    onChange={(e) => {
                      setValue("deleteCbx", e.target.checked ? true : false);
                      setDeleteCbx(!deleteCbx);
                    }}
                    checked={deleteCbx}
                  />
                </FormGroup>
              </Grid>*/}
              </Grid>
            </CardContent>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title=""
                titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
                action={
                  permissionData.update == true && (
                    <Button type="submit" variant="contained">
                      Save Permission
                    </Button>
                  )
                }
              />
            </Card>
          </Grid>

          {/*<CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={"Add Permission"}
          fullScreen
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-role">Select Role</InputLabel>
                  <Select
                    labelId="select-role"
                    id="select-role"
                    value={reValue}
                    label="role"
                    onChange={roleEHandleChange}
                    sx={{ width: "80%" }}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role._id} value={role._id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-permission">
                    Select Permission
                  </InputLabel>
                  <Select
                    labelId="select-permission"
                    id="select-permission"
                    placeholder="Select Permission"
                    value={peValue}
                    label="permission"
                    onChange={permissionEHandleChange}
                    sx={{ width: "80%" }}
                  >
                    {permissions.map((permission) => (
                      <MenuItem key={permission} value={permission}>
                        {permission}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel component="legend">Assign Permissions</FormLabel>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Create"
                    onChange={(e) => {
                      setValue("createCbx", e.target.checked ? true : false);
                      setCreateCbx(!createCbx);
                    }}
                    checked={false}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Read"
                    onChange={(e) => {
                      setValue("readCbx", e.target.checked ? true : false);
                      setReadCbx(!readCbx);
                    }}
                    checked={false}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Update"
                    onChange={(e) => {
                      setValue("updateCbx", e.target.checked ? true : false);
                      setUpdateCbx(!updateCbx);
                    }}
                    checked={false}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Delete"
                    onChange={(e) => {
                      setValue("deleteCbx", e.target.checked ? true : false);
                      setDeleteCbx(!deleteCbx);
                    }}
                    checked={false}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="align-right"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Button type="submit" color="primary" variant="contained">
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
                  </CustomizedDialogs>*/}
        </Grid>
      </form>
    </div>
  );
}

export default AssignPermission;
