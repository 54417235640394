import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../requestUrl';
import { Box } from '@mui/material';

const columns = [
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'email', headerName: 'Email', width: 350 },
  { field: 'role', headerName: 'Role', width: 300 },
  {
    field: 'status',
    headerName: 'Status',
    width: 300,

    sortable: true,
    valueGetter: (params) => `${params?.row?.status ? 'Active' : 'In Active'}`,
  },
];

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};
export default function DataTable() {
  const [tableData, setTableData] = useState([]);
  const token = sessionStorage.getItem('token');
  useEffect(() => {
    const getBusinessUserList = async () => {
      await axios({
        method: 'get',
        url: `${baseUrl}/admin/userlist`,
        headers: { Authorization: `jwt ${token}` },
      })
        .then((response) => {
          console.log('res', response);
          setTableData(response?.data?.data?.result);
        })
        .catch((err) => {
          console.log('err : ', err);
        });
    };
    getBusinessUserList();
  }, [token]);
  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);
  return (
    <>
      <p className="title">UserList</p>
      <Box component="div">
        {tableData && tableData.length > 0 ? (
          <DataGrid
            getRowId={(row) => row._id}
            rows={tableData}
            rowSelection={false}
            columns={columns}
            paginationModel={{ page: 0, pageSize: 5 }}
          />
        ) : null}
      </Box>
    </>
  );
}