import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  FormLabel,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  RadioGroup,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import axios from "../../utils/axios";
import {
  useGet,
  usePost,
  usePut,
  useDelete,
  addImage,
} from "../../hooks/useFetch";
import {
  MIDDLE_SECTION_API,
  CATEGORY_API,
  GET,
  POST,
  PUT,
  DELETE,
} from "../../api/config";
import NotificationContext from "../../store/notification-context";
import { appendImage } from "../../utils/helper";
import ImagePicker from "../ui/image-picker";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { insertLineBreaks } from "../../utils/helper";
import InfoIcon from '@mui/icons-material/Info';

function LPMiddleSection() {
  const [cId, setCId] = useState("");
  const [cValue, setCValue] = useState("");
  const [image, setImage] = useState(null);
  const [action, setAction] = useState("view");
  const [LPMiddleSectionList, setLPMiddleSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    MIDDLE_SECTION_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { data: categoryData } = useGet(CATEGORY_API[GET], {
    apiCall: "onload",
  });
  const { refresh: postData } = usePost(MIDDLE_SECTION_API[POST]);
  const { refresh: putData } = usePut(MIDDLE_SECTION_API[PUT]);
  const { refresh: delData } = useDelete(MIDDLE_SECTION_API[DELETE]);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setLPMiddleSectionList(getData.data.result || []);
    }
  }, [getData]);

  useEffect(() => {
    if (categoryData) {
      setCategoryList(categoryData.data || []);
    }
  }, [categoryData]);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
      sortable: true,
    },
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 150,
      sortable: true,
    },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => (
        <span>{params?.row?.active ? 'Active' : 'Inactive'}</span>
      )
    },
    {
      field: "url",
      headerName: "Url",
      width: 200,
      renderCell: (params) => {
        const linkText = params.value || '';
        const textWithLineBreaks = insertLineBreaks(linkText, 25);
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {textWithLineBreaks}
          </div>
        );
      }
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Category";
    } else if (action === "add") {
      return "Add Category";
    } else {
      return "Delete Category";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const editRowData = (params) => {
    const { title, active, image, url, categoryId, categoryName } = params.row;
    console.log(params.row, "params.row");
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("title", title);
    setValue("url", url);
    setValue("active", active.toString());
    setImage(image);
    console.log(categoryName, categoryId, categoryList);
    setCValue(categoryId);
  };

  const onSubmit = async (data) => {
    if (action === "add") {
      const formData = new FormData();
      const image = data.image[0];
      formData.append("image", image);
      formData.append("categoryName", data.category.name);
      formData.append("categoryId", data.category._id);
      formData.append("title", data.title);
      formData.append("url", data.url);
      axios({
        async: true,
        url: MIDDLE_SECTION_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    } else {
      const formData = new FormData();
      let image;
      if (typeof data.image === "object" && data.image.length > 0) {
        image = data.image[0];
        formData.append("image", image);
      }
      formData.append("image", image);
      formData.append("categoryName", categoryName);
      formData.append("categoryId", cId);
      formData.append("title", data.title);
      formData.append("active", data.active);
      formData.append("url", data.url);
      formData.append("_id", rowData._id);
      axios({
        async: true,
        url: [MIDDLE_SECTION_API][PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  const categoryHandleChange = (event) => {
    const { target } = event;
    console.log(target, "target");
    setCValue(target.value.name);
    // setCId(target.value._id);
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Manage Quick Links"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Category
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={LPMiddleSectionList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={125}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    placeholder="Enter Title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="url"
                    placeholder="Enter Url"
                    {...register("url", { required: true })}
                  />
                  {errors.url && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">
                    Active
                  </FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <label>
                        <div>
                          <input
                            type="radio"
                            value="true"
                            {...register("active", {
                              required: true,
                            })}
                          />
                          <span>Active</span>
                        </div>
                      </label>
                      <label>
                        <div>
                          <input
                            type="radio"
                            value="false"
                            {...register("active", {
                              required: true,
                            })}
                          />
                          <span>InActive</span>
                        </div>
                      </label>
                    </RadioGroup>
                    {errors.active && (
                      <span className="error-text">
                        This field is required.
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="select-category">
                          Select Category
                        </InputLabel>
                        <Select
                          labelId="select-category"
                          id="select-category"
                          label="Size Category"
                          {...field}
                        >
                          {categoryList.map((category) => (
                            <MenuItem key={category._id} value={category}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    name={"category"}
                    control={control}
                  />
                  {/* <FormControl fullWidth>
                  <InputLabel id="select-category">Select Category</InputLabel>
                  <Select
                    labelId="select-category"
                    id="select-category"
                    value={cValue}
                    label="category"
                    onChange={categoryHandleChange}
                  >
                    {categoryList.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 1200×1406 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default LPMiddleSection;
