import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  FormLabel,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  RadioGroup,
  Chip,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";

import {
  addImage,
  useDelete,
  useGet,
  usePost,
  usePut,
} from "../../hooks/useFetch";
import {
  PRODUCT_LIST_API,
  GET,
  POST,
  PUT,
  DELETE,
  CATEGORY_API,
  APPLIQUES_API,
  SUB_CATEGORY_API,
  TYPEDETAIL_API,
  TYPES_API,
  STITCHING_TYPE_API,
  LETTER_PLACEMENT_API,
  LETTER_SIZE_API,
  COLOR_API,
  SIZES_API,
  EMBROIDARY_API,
  EMBROIDARY_LOCATION_API,
  FONT_COLOR_API
} from "../../api/config";
import ImagePicker from "../ui/image-picker";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { formatPrice } from "../../utils/helper";
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { titleCase } from "../../utils/helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currencyArr = [
  {
    _id: "1",
    name: "$",
  },
];

const InfoTooltip = ({ messages, placement }) => {
  return (
    <Tooltip
      placement={placement}
      title={
        <React.Fragment>
          {messages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </React.Fragment>
      }
    >
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

const infoMessages = [
  'Image Resolution - 1200×1406 Pixels.',
  'Max image size: 1MB.'
];

function ProductList() {
  const [cValue, setCValue] = useState("");
  const [cIdValue, setCIdValue] = useState("");
  const [sValue, setSValue] = useState("");
  const [sIdValue, setSIdValue] = useState("");
  const [curValue, setCurValue] = useState("");
  const [tdValue, setTDValue] = useState("");
  const [typeValue, setTypeValue] = useState({});

  const [productList, setProductList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [appliqueId, setAppliqueId] = useState([]);
  const [appliqueList, setAppliqueList] = useState([]);
  const [typeDetails, setTypeDetails] = useState([]);
  const [types, setTypes] = useState([]);
  const [stitchingTypeId, setStitchingTypeId] = useState([]);
  const [stitchingTypes, setStitchingTypes] = useState([]);
  const [letterplacementId, setLetterplacementId] = useState([]);
  const [letterplacements, setLetterplacements] = useState([]);
  const [letterPlacementDetails, setLetterPlacementDetails] = useState([]);
  const [greekLetterSizeId, setGreekLetterSizeId] = useState([]);
  const [greekLetterSizes, setGreekLetterSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizeCodes, setSizeCodes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [embroidary, setEmbroidary] = useState([]);
  const [embroidaryId, setEmbroidaryId] = useState([]);

  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);
  const [action, setAction] = useState("view");
  const customSizeOrder = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];

  categories.sort((a, b) => a?.name?.localeCompare(b?.name));
  subCategories.sort((a, b) => a?.name?.localeCompare(b?.name));
  typeDetails.sort((a, b) => a?.name?.localeCompare(b?.name));
  appliqueList.sort((a, b) => a?.name?.localeCompare(b?.name));
  letterplacements.sort((a, b) => a?.name?.localeCompare(b?.name));
  greekLetterSizes.sort((a, b) => a?.size?.localeCompare(b?.size));
  sizes.sort((a, b) => {
    const indexA = customSizeOrder.indexOf(a.sizeCode);
    const indexB = customSizeOrder.indexOf(b.sizeCode);
    return indexA - indexB;
  });
  embroidary.sort((a, b) => a?.name?.localeCompare(b?.name));
  types.sort((a, b) => a?.name?.localeCompare(b?.name));
  colors.sort((a, b) => a?.colorName?.localeCompare(b?.colorName));
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      clearance: 'false',
      heatSealedLetter: 'false'
    },
  });

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "colors",
  });

  const { letterplacementIdFields } = useFieldArray({
    control,
    name: "letterplacementId",
  });

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    PRODUCT_LIST_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { data: getCategoryData } = useGet(CATEGORY_API[GET], {
    apiCall: "onload",
  });
  const { data: getSubCategoryData } = useGet(SUB_CATEGORY_API[GET], {
    apiCall: "onload",
  });
  const { data: getAppliqueData } = useGet(APPLIQUES_API[GET], {
    apiCall: "onload",
  });
  const { data: getTypeDetailData } = useGet(TYPEDETAIL_API[GET], {
    apiCall: "onload",
  });
  const { data: getTypeData } = useGet(TYPES_API[GET], {
    apiCall: "onload",
  });
  const { data: getStichingTypeData } = useGet(STITCHING_TYPE_API[GET], {
    apiCall: "onload",
  });
  const { data: getLetterPlacementData } = useGet(LETTER_PLACEMENT_API[GET], {
    apiCall: "onload",
  });
  const { data: getLetterSizeData } = useGet(LETTER_SIZE_API[GET], {
    apiCall: "onload",
  });
  const { data: getColorData } = useGet(FONT_COLOR_API[GET], {
    apiCall: "onload",
  });
  const { data: getSizesData } = useGet(SIZES_API[GET], {
    apiCall: "onload",
  });
  const { data: getEmbroidaryData } = useGet(EMBROIDARY_LOCATION_API[GET], {
    apiCall: "onload",
  });

  const { refresh: postData } = usePost(PRODUCT_LIST_API[POST]);
  const { refresh: putData } = usePut(PRODUCT_LIST_API[PUT]);
  const { refresh: delData } = useDelete(PRODUCT_LIST_API[DELETE]);

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setProductList(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    if (getCategoryData) {
      setCategories(getCategoryData.data || []);
    }
  }, [getCategoryData]);

  useEffect(() => {
    if (getSubCategoryData) {
      setSubCategories(getSubCategoryData.data || []);
    }
  }, [getSubCategoryData]);

  useEffect(() => {
    if (getAppliqueData) {
      setAppliqueList(getAppliqueData.data || []);
    }
  }, [getAppliqueData]);

  useEffect(() => {
    if (getTypeDetailData) {
      setTypeDetails(getTypeDetailData.data || []);
    }
  }, [getTypeDetailData]);

  useEffect(() => {
    if (getTypeData) {
      setTypes(getTypeData.data || []);
    }
  }, [getTypeData]);

  useEffect(() => {
    if (getStichingTypeData) {
      setStitchingTypes(getStichingTypeData.data || []);
    }
  }, [getStichingTypeData]);

  useEffect(() => {
    if (getLetterPlacementData) {
      setLetterplacements(getLetterPlacementData.data || []);
    }
  }, [getLetterPlacementData]);

  useEffect(() => {
    if (getLetterSizeData) {
      setGreekLetterSizes(getLetterSizeData.data || []);
    }
  }, [getLetterSizeData]);

  useEffect(() => {
    if (getColorData) {
      getColorData.data.forEach((color) => {
        color.colorCodeName = `${color.name + ' - ' + color.value}`;
      });
      console.log(getColorData.data);
      setColors(getColorData.data || []);
    }
  }, [getColorData]);

  useEffect(() => {
    if (getSizesData) {
      getSizesData.data.forEach((size) => {
        size.sizeCodeName = `${size.sizeName + ' - ' + size.sizeCode}`;
      });
      console.log("setSizes : ", getSizesData.data);
      getSizesData.data.push({
          "_id": "63e64fc128b14ddf39d21ghj",
          "sizeName": "QTY",
          "sizeCode": "QTY",
          "createdAt": "2023-07-10T20:01:03.704Z",
          "updatedAt": "2023-07-10T20:01:03.704Z",
          "__v": 0,
          "sizeCodeName": "QTY - QTY"
      });
      setSizes(getSizesData.data || []);
    }
  }, [getSizesData]);

  useEffect(() => {
    if (getEmbroidaryData) {
      setEmbroidary(getEmbroidaryData.data || []);
    }
  }, [getEmbroidaryData]);

  const columns = [
    { field: "cName", headerName: "Category", width: 200, sortable: true },
    {
      field: "sName",
      headerName: "Sub-Category",
      width: 200,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Product",
      width: 300,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return <span>{formatPrice(params.value)}</span>
      }
    },
    /* {
      field: "priority",
      headerName: "Priority",
      width: 100,
      sortable: true,
    }, */
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const editRowData = (params) => {
    const {
      name,
      clearance,
      currency,
      description,
      gender,
      heatSealedLetter,
      includes,
      item,
      price,
      /* priority, */
      colors,
      imgSize,
      stitchingTypeId,
      letterplacementId,
      totalQuantity,
      typeDetailId,
      appliqueId,
      greekLetterSizeId,
      size,
      embroidaryId,
      categoryId,
      subCategoryId,
      typeId,
    } = params.row;
    console.log(params.row, "params.row");
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("name", name);
    setValue("clearance", clearance.toString());
    setValue("currency", currency);
    setValue("description", description);
    setValue("gender", gender);
    setValue("heatSealedLetter", heatSealedLetter.toString());
    setValue("includes", includes);
    setValue("item", item);
    setValue("price", price);
    /* setValue("priority", priority); */
    setValue("item", item);
    setValue("totalQuantity", totalQuantity);
    
    colors.forEach(color => {
      color.value = `${color.colorName} - ${color.colorCode}`;
    });
    setValue("colors", colors);
    setValue("imgSize.width", imgSize.width);
    setValue("imgSize.height", imgSize.height);
    setValue("typeValue", typeId);
    setValue("categoryId", categoryId);
    setValue("subCategoryId", subCategoryId);
    setCurValue(currency);
    setTDValue(typeDetailId);
    setAppliqueId(appliqueId);
    setStitchingTypeId(stitchingTypeId);

    let letterPlacementIdArr = letterplacementId.map((lp) => typeof lp === "string"?lp:lp._id);
    console.log("letterPlacementIdArr:", letterPlacementIdArr);

    setLetterplacementId(letterPlacementIdArr);
    setLetterPlacementDetails(letterplacementId);
    setGreekLetterSizeId(greekLetterSizeId);
    console.log("size : ", size);
    setSizeCodes(size);
    setEmbroidaryId(embroidaryId);
    setCIdValue(categoryId);
    setSIdValue(subCategoryId);

    /* get specific type */
    let selectedType = types.filter((itm) => itm._id === params?.row?.typeId);
    console.log("selectedType : ", selectedType);
    
    if(selectedType.length > 0){
      setTypeValue(selectedType[0]);
    } else {
      setTypeValue({
        "image": params?.row?.colors[0]?.images ?
          Object.keys(params.row.colors[0].images) : null
      });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Product";
    } else if (action === "add") {
      return "Add Product";
    } else {
      return "Delete Product";
    }
  };

  const closeModal = () => {
    setModal(false);
    window.location.reload(true);
  };

  const currencyHandleChange = (event) => {
    setCurValue(event.target.value);
  };

  const typeDetailHandleChange = (event) => {
    setTDValue(event.target.value);
  };

  const stitchingTypeHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setStitchingTypeId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const letterPlacementHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("letterPlacementHandleChange value ; ", value);
    setLetterplacementId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const letterSizeHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setGreekLetterSizeId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const sizeHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSizeCodes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const embroidaryHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEmbroidaryId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const appliqueHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAppliqueId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const uploadColorsImage = async (colors) => {
    if (Array.isArray(colors) && colors.length > 0) {
      for (let color of colors) {
        for (let img in color.images) {
          if (color.images[img] && typeof color.images[img] === "object") {
            const image = await addImage(color.images[img]);
            color.images[img] = image[0] ?? "";
          }
          else if (color.images[img]) {
            // do nothing
          } else {
            color.images[img] = "";
          }
        }
      }
    }
    return colors;
  };

  const onSubmit = async (data) => {
    
    data.colors.forEach(color => {
      
      if (color.value) {
        let colorParts = color.value.split(' - ');
        color.colorName = colorParts[0];
        color.colorCode = colorParts[1];
        color.value = colorParts[1];
      }

      if (color['size&quantity']) {
        color['size&quantity'].forEach(sizeObj => {
          if (sizeObj.sizeCodeName) {
            let sizeParts = sizeObj.sizeCodeName.split(' - ');
            sizeObj.sizeName = sizeParts[0];
            sizeObj.sizeCode = sizeParts[1];
            sizeObj.quantity = 10;
            sizeObj.sizeCodeName = `${sizeParts[0] + ' - ' + sizeParts[1]}`;
          }
        });
      }
    });

    console.log("letterplacementId ", letterplacementId);
    console.log('form letterplacementId : ', data.letterplacementId);

    let letterPlacementArr = letterplacements.filter((lp) => letterplacementId.includes(lp._id)).map((lp) => {
      if(letterplacementId.includes(lp._id)){
        let fieldV = data.letterplacementId.filter((l) => l._id == lp._id).pop();
        return {
          ...lp,
          x_axis: fieldV.x_axis,
          y_axis: fieldV.y_axis,
        }
      }
    });
    console.log('letterPlacementArr : ', letterPlacementArr);

    let stitchingTypeId = stitchingTypes.map((st) => st._id);
    
    try {
      setIsLoading(true);
      setSubmitError('');
      delete data.typedetail;
      delete data.typeValue;
      data = {
        ...data,
        typeDetailId: tdValue,
        appliqueId: appliqueId,
        currency: curValue,
        embroidaryId: embroidaryId,
        greekLetterSizeId: greekLetterSizeId,
        letterplacementId: letterPlacementArr,
        stitchingTypeId: stitchingTypeId,
        typeId: typeValue._id,
        size: sizeCodes,
        categoryId: cIdValue,
        subCategoryId: sIdValue,
        imgSize: { height: "67", width: "45" },
        totalQuantity: 1000,
        priority: (productList.length+1)
      };
      if (action === "add") {
        const colors = await uploadColorsImage(data.colors);

        data = {
          ...data,
          cName: cValue,
          sName: sValue,
          colors,
        };
        console.log("data", data);
        postData({ data }, { refreshGetData, closeModal });
      } else {
        const colors = await uploadColorsImage(data.colors);
        delete data.priority;
        //delete data.letterplacementId;
        delete data.typeValue;
        //delete data.stitchingTypeId;
        data = {
          ...data,
          colors,
          totalQuantity: data.totalQuantity.toString(),
          _id: rowData._id,
        };
        console.log(data, "data");
        putData({ data }, { refreshGetData, closeModal });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setSubmitError('An error occurred while submitting the form. Please try again.');
    }
  };

  const convertToPreviewSideName = (side, type) => {
    if(typeof side === "undefined" || side == null) return '';
    //console.log("convertToPreviewSideName side : ", side);
    let sideVal = side.trim().toLowerCase().replace(/\s/g, "_");

    /* console.log("sideVal : ", sideVal); */
    let actualSideVal = "";
    switch (sideVal) {
      case "front":
        actualSideVal = "front";
        break;
      case "chest":
        actualSideVal = "front";
        break;
      case "left_chest":
        actualSideVal = "front";
        break;
      case "right_chest":
        actualSideVal = "front";
        break;
      case "back":
        actualSideVal = "back";
        break;
      case "crest_and_images(price_varies_with_size_and_design)":
        actualSideVal = "crest_and_images";
        break;
      case "left_arm":
        actualSideVal = "left_arm";
        break;
      case "right_arm":
        actualSideVal = "right_arm";
        break;
      case "left_cuff":
        actualSideVal = "left_cuff";
        break;
      case "right_cuff":
        actualSideVal = "right_cuff";
        break;   
      case "collar":
        actualSideVal = type=="hooded"?"hood":"on_collar";
        break;
      case "collar_flipper_up":
        actualSideVal = type=="hooded"?"hood_backward":"collar_flipped_up";
        break;
      default:
        actualSideVal = sideVal;
        break;
    }

    return actualSideVal; //titleCase(actualSideVal.replace(/_/g, " "));
  }

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{ paddingTop: "0" }}>
        <Card>
          <CardHeader
            title="Manage Products"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Product
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={productList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={55}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
          <CustomizedDialogs
            showModal={modal}
            closeModal={closeModal}
            modalTitle={getModalTitle()}
            fullScreen
          >
            {action === "delete" ? (
              <div>
                <p>Are you sure want to Delete?</p>
                <div
                  className="align-right"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    onClick={deleteHandler}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="select-category">
                            Select Category *
                          </InputLabel>
                          <Select
                            labelId="select-category"
                            id="select-category"
                            label="Size Category"
                            {...field}
                            onChange={(e) => {
                              const category = categories.find(
                                (c) => c._id === e.target.value
                              );
                              field.onChange(e);
                              setCValue(category.name);
                              setCIdValue(e.target.value);
                            }}
                          >
                            {categories.map((category) => (
                              <MenuItem key={category._id} value={category._id}>
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      name="categoryId"
                      control={control}
                      rules={{ required: 'Category is required' }}
                    />
                    {errors.categoryId && (
                      <span className="error-text">{errors.categoryId.message}</span>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="select-subCategory">
                            Select SubCategory *
                          </InputLabel>
                          <Select
                            labelId="select-subCategory"
                            id="select-subCategory"
                            label="Size SubCategory"
                            {...field}
                            onChange={(e) => {
                              const subCategory = subCategories.find(
                                (c) => c._id === e.target.value
                              );
                              field.onChange(e);
                              setSValue(subCategory.name);
                              setSIdValue(e.target.value);
                            }}
                          >
                            {subCategories.map((subCategory) => (
                              <MenuItem
                                key={subCategory._id}
                                value={subCategory._id}
                              >
                                {subCategory.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      name="subCategoryId"
                      control={control}
                      rules={{ required: 'SubCategory is required' }}
                    />
                    {errors.subCategoryId && (
                      <span className="error-text">{errors.subCategoryId.message}</span>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Name *"
                      placeholder="Enter Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Description *"
                      placeholder="Enter Description"
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Includes *"
                      placeholder="Enter Includes"
                      {...register("includes", { required: true })}
                    />
                    {errors.includes && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Item *"
                      placeholder="Enter Item"
                      {...register("item", { required: true })}
                    />
                    {errors.item && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Base Price *"
                      placeholder="Enter Base Price"
                      {...register("price", { required: true })}
                    />
                    {errors.price && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </Grid>
                  {/*<Grid item xs={4}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Priority *"
                      placeholder="Enter Priority"
                      {...register("priority", { required: true })}
                    />
                    {errors.priority && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </Grid>*/}
                  {/* <Grid item xs={3}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Total Quantity"
                    placeholder="Enter Total Quantity"
                    {...register("totalQuantity", { required: true })}
                  />
                  {errors.totalQuantity && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid> */}
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel id="clearance-radio">Clearance</FormLabel>
                      <RadioGroup
                        aria-labelledby="clearance-radio"
                        name="radio-buttons-group"
                      >
                        <label>
                          <div>
                            <input
                              type="radio"
                              value="true"
                              {...register("clearance", { required: true })}
                            />
                            <span>Active</span>
                          </div>
                        </label>
                        <label>
                          <div>
                            <input
                              type="radio"
                              value="false"
                              {...register("clearance")}
                            />
                            <span>InActive</span>
                          </div>
                        </label>
                      </RadioGroup>
                      {errors.clearance && (
                        <span className="error-text">This field is required.</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel id="gender-radio">Gender *</FormLabel>
                      <RadioGroup
                        aria-labelledby="gender-radio"
                        name="radio-buttons-group"
                      >
                        <label>
                          <div>
                            <input
                              type="radio"
                              value="male"
                              {...register("gender", { required: true })}
                            />
                            <span>Unisex</span>
                          </div>
                        </label>
                        <label>
                          <div>
                            <input
                              type="radio"
                              value="female"
                              {...register("gender")}
                            />
                            <span>Female</span>
                          </div>
                        </label>
                      </RadioGroup>
                      {errors.gender && (
                        <span className="error-text">This field is required.</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel id="sealLetter-radio">
                        Heat Sealed Letter
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="sealLetter-radio"
                        name="radio-buttons-group"
                      >
                        <label>
                          <div>
                            <input
                              type="radio"
                              value="true"
                              {...register("heatSealedLetter", { required: true })}
                            />
                            <span>Active</span>
                          </div>
                        </label>
                        <label>
                          <div>
                            <input
                              type="radio"
                              value="false"
                              {...register("heatSealedLetter")}
                            />
                            <span>InActive</span>
                          </div>
                        </label>
                      </RadioGroup>
                      {errors.heatSealedLetter && (
                        <span className="error-text">This field is required.</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="select-currency">
                        Select Currency *
                      </InputLabel>
                      <Controller
                        control={control}
                        name="currency"
                        rules={{ required: 'Please select a currency' }}
                        render={({ field }) => (
                          <Select
                            labelId="select-currency"
                            id="select-currency"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setCurValue(e.target.value);
                            }}
                            label="currency"
                          >
                            {currencyArr.map((currency) => (
                              <MenuItem key={currency.name} value={currency.name}>
                                {currency.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.currency && (
                        <span style={{ color: 'red' }}>{errors.currency.message}</span>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="select-typeDetail">
                        Select Type Detail *
                      </InputLabel>
                      <Controller
                        control={control}
                        name="typedetail"
                        // rules={{ required: 'Please select a type detail' }}
                        render={({ field }) => (
                          <Select
                            labelId="select-typeDetail"
                            id="select-typeDetail"
                            value={field.value}
                            label="Type Detail"
                            onChange={(e) => {
                              // field.onChange(e.target.value);
                              setTDValue(e.target.value);
                            }}
                          >
                            {typeDetails.map((typedetail) => (
                              <MenuItem key={typedetail._id} value={typedetail._id}>
                                {typedetail.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {/* {errors.typedetail && (
                        <span style={{ color: 'red' }}>{errors.typedetail.message}</span>
                      )} */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="applique-multiple-checkbox-label">
                        Decoration Options
                      </InputLabel>
                      <Select
                        labelId="applique-multiple-checkbox-label"
                        id="applique-multiple-checkbox"
                        multiple
                        value={appliqueId}
                        onChange={appliqueHandleChange}
                        input={<OutlinedInput label="Decoration Options" />}
                        renderValue={(selected) =>
                          selected.map((select) => (
                            <Chip
                              key={select}
                              label={
                                appliqueList?.find((e) => e._id === select).name
                              }
                            />
                          ))
                        }
                        MenuProps={MenuProps}
                      >
                        {appliqueList.map((ap) => {
                          return (
                            <MenuItem key={ap._id} value={ap._id}>
                              <Checkbox checked={appliqueId.includes(ap._id)} />
                              <ListItemText primary={ap.name} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="stitching-type-multiple-checkbox-label">
                    Stitching Types
                  </InputLabel>
                  <Select
                    labelId="stitching-type-multiple-checkbox-label"
                    id="stitching-type-multiple-checkbox"
                    multiple
                    value={stitchingTypeId}
                    onChange={stitchingTypeHandleChange}
                    input={<OutlinedInput label="Stitching Type" />}
                    renderValue={(selected) =>
                      selected.map((select) => (
                        <Chip
                          key={select}
                          label={
                            stitchingTypes?.find((e) => e._id === select)
                              .stitchingType
                          }
                        />
                      ))
                    }
                    MenuProps={MenuProps}
                  >
                    {stitchingTypes.map((st) => {
                      return (
                        <MenuItem key={st._id} value={st._id}>
                          <Checkbox
                            checked={stitchingTypeId.includes(st._id)}
                          />
                          <ListItemText primary={st.stitchingType} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid> */}
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="letter-placement-multiple-checkbox-label">
                        Letter Placement
                      </InputLabel>
                      <Select
                        labelId="letter-placement-multiple-checkbox-label"
                        id="letter-placement-multiple-checkbox"
                        multiple
                        value={letterplacementId}
                        onChange={letterPlacementHandleChange}
                        input={<OutlinedInput label="Letter Placement" />}
                        renderValue={(selected) =>
                          selected.map((select) => (
                            <Chip
                              key={select}
                              label={
                                letterplacements?.find((e) => e._id === select)
                                  ?.name
                              }
                            />
                          ))
                        }
                        MenuProps={MenuProps}
                      >
                        {letterplacements.map((lp) => {
                          return (
                            <MenuItem key={lp._id} value={lp._id}>
                              <Checkbox
                                checked={letterplacementId.includes(lp._id)}
                              />
                              <ListItemText primary={lp.name} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="letter-size-multiple-checkbox-label">
                        Letter Size
                      </InputLabel>
                      <Select
                        labelId="letter-size-multiple-checkbox-label"
                        id="letter-size-multiple-checkbox"
                        multiple
                        value={greekLetterSizeId}
                        onChange={letterSizeHandleChange}
                        input={<OutlinedInput label="Letter Size" />}
                        renderValue={(selected) =>
                          selected.map((select) => (
                            <Chip
                              key={select}
                              label={
                                greekLetterSizes?.find((e) => e._id === select)
                                  .size
                              }
                            />
                          ))
                        }
                        MenuProps={MenuProps}
                      >
                        {greekLetterSizes.map((ls) => {
                          return (
                            <MenuItem key={ls._id} value={ls._id}>
                              <Checkbox
                                checked={greekLetterSizeId.includes(ls._id)}
                              />
                              <ListItemText primary={ls.size} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {letterplacementId.length > 0 && <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="select-type">Letter Placement Position Coordinates *</InputLabel>
                      {letterplacements.map((lp, index) => {
                        if(letterplacementId.includes(lp._id)){
                          let details = letterPlacementDetails.filter(p => p._id == lp._id).pop();
                          return (
                            <div
                              key={index}
                              style={{
                                border: "1px solid #2065D1",
                                padding: "10px",
                              }}
                            >
                              <div
                                key={lp.name}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "20px 0",
                                }}
                              >
                                <Grid item xs={4}>
                                  <p>{lp.name}</p>
                                  <Controller
                                    render={({ field }) => (
                                      
                                      <></>
                                    )}
                                    name={`letterplacementId[${index}]._id`}
                                    control={control}
                                    defaultValue={lp._id}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Controller
                                    render={({ field }) => (
                                      <TextField
                                        
                                        type="text"
                                        label="X-Axis *"
                                        placeholder="Enter X-Axis"
                                        {...field}
                                        onBlur={(e) => {
                                          let value = e.target.value.trim();
                                          value = value.replace(/[^\d.]/g, '');
                                          
                                          field.onChange(value);
                                        }}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        rules={{ required: true }}
                                        // error={!!errors.colors?.[nestIndex]?.size?.[k]?.sizePrice}
                                        helperText={
                                          errors.letterplacementId?.[index]?.x_axis?.message && (
                                            <span style={{ color: 'red' }}>
                                              {errors.letterplacementId?.[index]?.x_axis?.message}
                                            </span>
                                          )
                                        }
                                      />
                                    )}
                                    name={`letterplacementId[${index}].x_axis`}
                                    control={control}
                                    defaultValue={details?details.x_axis:""}
                                    rules={{ required: 'x_axis is required' }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Controller
                                    render={({ field }) => (
                                      <TextField
                                        
                                        type="text"
                                        label="Y-Axis *"
                                        placeholder="Enter Y-Axis"
                                        {...field}
                                        onBlur={(e) => {
                                          let value = e.target.value.trim();
                                          value = value.replace(/[^\d.]/g, '');
                                          
                                          field.onChange(value);
                                        }}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        rules={{ required: true }}
                                        // error={!!errors.colors?.[nestIndex]?.size?.[k]?.sizePrice}
                                        helperText={
                                          errors.letterplacementId?.[index]?.y_axis?.message && (
                                            <span style={{ color: 'red' }}>
                                              {errors.letterplacementId?.[index]?.y_axis?.message}
                                            </span>
                                          )
                                        }
                                      />
                                    )}
                                    name={`letterplacementId[${index}].y_axis`}
                                    control={control}
                                    defaultValue={details?details.y_axis:""}
                                    rules={{ required: 'y_axis is required' }}
                                  />
                                </Grid>
                                
                                
                              </div>
                            </div>
                          )
                        }
                      })}
                    </FormControl>
                  </Grid>}
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="size-multiple-checkbox-label">
                        Size
                      </InputLabel>
                      <Select
                        labelId="size-multiple-checkbox-label"
                        id="size-multiple-checkbox"
                        multiple
                        value={sizeCodes}
                        onChange={sizeHandleChange}
                        input={<OutlinedInput label="Size" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {sizes.map((sz) => {
                          return (
                            <MenuItem key={sz.sizeCode} value={sz.sizeCode}>
                              <Checkbox
                                checked={sizeCodes.indexOf(sz.sizeCode) > -1}
                              />
                              <ListItemText primary={sz.sizeCode} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="embroidary-multiple-checkbox-label">
                      Embroidery Locations
                      </InputLabel>
                      <Select
                        labelId="embroidary-multiple-checkbox-label"
                        id="embroidary-multiple-checkbox"
                        multiple
                        value={embroidaryId}
                        onChange={embroidaryHandleChange}
                        input={<OutlinedInput label="Embroidery Locations" />}
                        renderValue={(selected) =>
                          selected.map((select) => (
                            <Chip
                              key={select}
                              label={
                                embroidary?.find((e) => e._id === select)?.name
                              }
                            />
                          ))
                        }
                        MenuProps={MenuProps}
                      >
                        {embroidary.map((em) => {
                          return (
                            <MenuItem key={em._id} value={em._id}>
                              <Checkbox checked={embroidaryId.includes(em._id)} />
                              <ListItemText primary={em.name} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="select-type">Titles *</InputLabel>
                          <Select
                            labelId="select-type"
                            id="select-type"
                            label="Titles"
                            {...field}
                            onChange={(event) => {
                              event.preventDefault();
                              field.onChange(event);
                              const type = types.find(
                                (t) => t._id === event.target.value
                              );
                              console.log("type +++++++++++", type);
                              setTypeValue(type);
                            }}
                          >
                            {types.map((type) => (
                              <MenuItem key={type._id} value={type._id}>
                                {type.name}
                                {type.type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      name={"typeValue"}
                      control={control}
                      rules={{ required: 'Please select a title' }}
                    />
                    {errors.typeValue && (
                      <span style={{ color: 'red' }}>{errors.typeValue.message}</span>
                    )}
                  </Grid>
                  {/* <Grid item xs={3}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Image Height"
                    placeholder="Enter Image Height"
                    {...register("imgSize.height", { required: true })}
                  />
                  {errors.imgSize?.height && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid> */}
                  {/* <Grid item xs={3}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Image Width"
                    placeholder="Enter Image Width"
                    {...register("imgSize.width", { required: true })}
                  />
                  {errors.imgSize?.width && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid> */}
                  <Grid item xs={12}>
                    {fields.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #2065D1",
                            padding: "10px",
                          }}
                        >
                          <div
                            key={item.name}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              margin: "20px 0",
                            }}
                          >
                            <p>{index + 1}</p>
                            <Controller
                              render={({ field }) => (
                                <FormControl fullWidth>
                                  <InputLabel id="select-colorName">
                                    Select Color *
                                  </InputLabel>
                                  <Select
                                    labelId="select-colorName"
                                    id="select-colorName"
                                    label="Color Name"
                                    {...field}
                                  >
                                    {colors.map((color) => (
                                      
                                      <MenuItem
                                        key={color._id}
                                        value={`${color.name} - ${color.value}`}
                                      >{console.log(color)}
                                        <div
                                          style={{
                                            width: 30,
                                            height: 30,
                                            backgroundColor: color.value,
                                            marginRight: 10,
                                            border: '1px solid #ccc',
                                          }}
                                        ></div>
                                        {`${color.name} - ${color.value}`}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {errors?.colors?.[index]?.value && (
                                    <span style={{ color: 'red' }}>Color is required</span>
                                  )}
                                </FormControl>
                              )}
                              name={`colors.${index}.value`}
                              control={control}
                              rules={{ required: 'Color is required' }}
                            />
                            {/* <Controller
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id="select-colorCode">
                                  Select Color Code
                                </InputLabel>
                                <Select
                                  labelId="select-colorCode"
                                  id="select-colorCode"
                                  label="Color Code"
                                  {...field}
                                >
                                  {colors.map((color) => (
                                    <MenuItem
                                      key={color._id}
                                      value={color.colorCode}
                                    >
                                      {color.colorCode}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                            name={`colors.${index}.colorCode`}
                            control={control}
                          /> */}
                            <IconButton
                              aria-label="delete"
                              color="outlined"
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            
                            {typeValue?.image?.map((type) => {
                              //console.log("type value : ", type);
                              let initType = type;
                              type = convertToPreviewSideName(type, typeValue.type);
                              /* console.log("loop type : ", type, "typeValue.type : ", typeValue.type); */
                              if(initType == "collar_flipper_up" && typeValue.type == "hooded"){
                                return false;
                              }

                              return (
                                <Grid item xs={4} key={type}>
                                  <Controller
                                    control={control}
                                    name={`colors.${index}.images.${type}`}
                                    rules={{
                                      validate: {
                                        imageSize: (value) => {
                                          if (!value) {
                                            return true;
                                          }
                                          if (value[0]?.size > 1 * 1024 * 1024) {
                                            return 'Image size should not exceed 1MB.';
                                          }
                                          return true;
                                        },
                                      },
                                    }}
                                    render={({
                                      field: { value, onChange, ...field },
                                    }) => {
                                      return (
                                        <Grid item xs={12}>
                                          <Box display="inline-flex" alignItems="center">
                                            <ImagePicker
                                              {...{
                                                register,
                                                errors,
                                                field,
                                                onChange,
                                              }}
                                              value={type}
                                              image={value}
                                            />
                                            <InfoTooltip messages={infoMessages} placement="right" />
                                          </Box>
                                          {errors.colors?.[index]?.images?.[type]?.message && (
                                            <span style={{ color: 'red' }}>
                                              {errors.colors[index].images[type].message}
                                            </span>
                                          )}
                                        </Grid>
                                      );
                                    }}
                                  />
                                </Grid>
                              );
                            })}
                            {/* <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`colors.${index}.images.left`}
                            // rules={{ required: "Left Image is required" }}
                            render={({
                              field: { value, onChange, ...field },
                            }) => {
                              return (
                                <Grid item xs={12}>
                                  <ImagePicker
                                    {...{ register, errors, field, onChange }}
                                    value={"image"}
                                    image={value}
                                  />
                                </Grid>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`colors.${index}.images.right`}
                            // rules={{ required: "Right Image is required" }}
                            render={({
                              field: { value, onChange, ...field },
                            }) => {
                              return (
                                <Grid item xs={12}>
                                  <ImagePicker
                                    {...{ register, errors, field, onChange }}
                                    value={"image"}
                                    image={value}
                                  />
                                </Grid>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`colors.${index}.images.front`}
                            // rules={{ required: "Front Image is required" }}
                            render={({
                              field: { value, onChange, ...field },
                            }) => {
                              return (
                                <Grid item xs={12}>
                                  <ImagePicker
                                    {...{ register, errors, field, onChange }}
                                    value={"image"}
                                    image={value}
                                  />
                                </Grid>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name={`colors.${index}.images.back`}
                            // rules={{ required: "Back Image is required" }}
                            render={({
                              field: { value, onChange, ...field },
                            }) => {
                              return (
                                <Grid item xs={12}>
                                  <ImagePicker
                                    {...{ register, errors, field, onChange }}
                                    value={"image"}
                                    image={value}
                                  />
                                </Grid>
                              );
                            }}
                          />
                        </Grid> */}
                          </div>
                          <NestedArray
                            sizes={sizes}
                            nestIndex={index}
                            {...{ control, register, errors }}
                          />
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className="align-right"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          append({
                            colorName: "",
                            colorCode: "",
                          });
                        }}
                      >
                        Add Colors
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className="align-right"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Button type="submit" color="primary" variant="contained">
                        Submit
                      </Button>
                      {isLoading && (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(255, 255, 255, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                      {submitError && <div style={{ color: 'red' }}>{submitError}</div>}
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </CustomizedDialogs>
        )}
    </Grid>
  );
}

const NestedArray = ({ sizes, nestIndex, control, register, errors }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `colors[${nestIndex}].size&quantity`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div
            key={item.name}
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 10px",
            }}
          >
            <p style={{ marginRight: "5px" }}>
              {nestIndex + 1}
              {"."}
              {k + 1}
            </p>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select-sizeName">
                      Select Size *
                    </InputLabel>
                    <Select
                      labelId="select-sizeName"
                      id="select-sizeName"
                      label="Size Name"
                      {...field}
                    >
                      {sizes.map((size) => (
                        <MenuItem key={size._id} value={size.sizeCodeName}>
                          {`${size.sizeCode + ' - ' + size.sizeName}`}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors?.colors?.[nestIndex]?.['size&quantity']?.[k]?.sizeCodeName && (
                      <span style={{ color: 'red' }}>Size is required</span>
                    )}
                  </FormControl>
                )}
                name={`colors[${nestIndex}].size&quantity[${k}].sizeCodeName`}
                control={control}
                rules={{ required: 'Size is required' }}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select-code">Select Code</InputLabel>
                    <Select
                      labelId="select-code"
                      id="select-code"
                      label="Size Code"
                      {...field}
                    >
                      {sizes.map((size) => (
                        <MenuItem key={size._id} value={size.sizeCode}>
                          {size.sizeCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name={`colors[${nestIndex}].size&quantity[${k}].sizeCode`}
                control={control}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Price *"
                    placeholder="Enter Size Price"
                    {...field}
                    onBlur={(e) => {
                      let value = e.target.value.trim();
                      value = value.replace(/[^\d.]/g, '');
                      if (value) {
                        const parts = value.split('.');
                        if (!parts[1] || parts[1].length === 0 || parts[1].length === 1) {
                          value = parts[0] + (parts[1] ? '.' + parts[1] : '.00');
                        } else if (parts[1].length > 2) {
                          value = parts[0] + '.' + parts[1].slice(0, 2);
                        }
                      }
                      field.onChange(value);
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    rules={{ required: true }}
                    // error={!!errors.colors?.[nestIndex]?.size?.[k]?.sizePrice}
                    helperText={
                      errors.colors?.[nestIndex]?.['size&quantity']?.[k]?.sizePrice?.message && (
                        <span style={{ color: 'red' }}>
                          {errors.colors?.[nestIndex]?.['size&quantity']?.[k]?.sizePrice?.message}
                        </span>
                      )
                    }
                  />
                )}
                name={`colors[${nestIndex}].size&quantity[${k}].sizePrice`}
                control={control}
                rules={{ required: 'Price is required' }}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    label="Quantity"
                    placeholder="Enter Quantity"
                    rules={{ required: true }}
                    {...field}
                  />
                )}
                name={`colors[${nestIndex}].size&quantity[${k}].quantity`}
                control={control}
              />
            </Grid> */}
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => remove(k)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      })}

      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          append({
            sizeName: "",
            sizeCode: "",
            sizePrice: "",
            quantity: "",
          });
        }}
      >
        Add Sizes
      </Button>
    </div>
  );
};

export default ProductList;
