// export const BASE_URL = "http://3.109.209.115:3000/";
export const BASE_URL = "https://admin.favorsbythebay.com/apis";

export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const DELETE = "delete";

export const Order_API = {
  get: "/order/get",
  post: "/order/add",
  put: "/order/update",
  delete: "/order/delete",
};

export const Module_API = {
  get: "/module/get",
  post: "/module/add",
  put: "/module/update",
  delete: "/module/delete",
};

export const Role_API = {
  get: "/role/get",
  post: "/role/add",
  put: "/role/update",
  delete: "/role/delete",
};

export const BACKGROUND_COLOR_API = {
  get: "/backgroundcolor/get",
  post: "/backgroundcolor/add",
  put: "/backgroundcolor/update",
  delete: "/backgroundcolor/delete",
};

export const BACKGROUND_MATERIAL_API = {
  get: "/backgroundmaterial/get",
  post: "/backgroundmaterial/add",
  put: "/backgroundmaterial/update",
  delete: "/backgroundmaterial/delete",
};

export const CATEGORY_API = {
  get: "/public/category",
  post: "/category/add",
  put: "/category/update",
  delete: "/category/delete",
};

export const COLOR_API = {
  get: "/color/get",
  post: "/color/add",
  put: "/color/update",
  delete: "/color/delete",
};

export const FONT_COLOR_API = {
  get: "/fontcolor/get",
  post: "/fontcolor/add",
  put: "/fontcolor/update",
  delete: "/fontcolor/delete",
};

export const FONT_STYLE_API = {
  get: "/fontstyle/get",
  post: "/fontstyle/add",
  put: "/fontstyle/update",
  delete: "/fontstyle/delete",
};

export const FOREGROUND_COLOR_API = {
  get: "/foregroundcolor/get",
  post: "/foregroundcolor/add",
  put: "/foregroundcolor/update",
  delete: "/foregroundcolor/delete",
};

export const FOREGROUND_MATERIAL_API = {
  get: "/foregroundmaterial/get",
  post: "/foregroundmaterial/add",
  put: "/foregroundmaterial/update",
  delete: "/foregroundmaterial/delete",
};

export const LANDING_PAGE_API = {
  get: "/landingpage/get",
  post: "/landingpage/add",
  put: "/landingpage/update",
  delete: "/landingpage/delete",
};

export const MIDDLE_SECTION_API = {
  get: "/middlesection/get",
  post: "/middlesection/add",
  put: "/middlesection/update",
  delete: "/middlesection/delete",
};

export const MARQUEE_API = {
  get: "/marquee/get",
  put: "/marquee/update",
};

export const SPECIAL_OFFER_API = {
  get: "/specialoffer/get",
  post: "/specialoffer/add",
  put: "/specialoffer/update",
  delete: "/specialoffer/delete",
};

export const SUB_CATEGORY_API = {
  get: "/public/subcategory",
  post: "/subcategory/add",
  put: "/subcategory/update",
  delete: "/subcategory/delete",
};

export const THREAD_TO_MATCH_API = {
  get: "/threadtomatch/get",
  post: "/threadtomatch/add",
  put: "/threadtomatch/update",
  delete: "/threadtomatch/delete",
};

export const ASSIGN_PERMISSION_API = {
  get: "/permission/getassigned",
  post: "/permission/multiassign",
};

export const PERMISSION_API = {
  get: "/permission/get",
  post: "/permission/assign",
};

export const PERMISSION_MULTIPLE_API = {
  post: "/permission/assign",
};

export const PRODUCT_LIST_API = {
  get: "/public/productlist",
  post: "/product/add",
  put: "/product/update",
  delete: "/product/delete",
};

export const TYPES_API = {
  get: "/types/get",
  post: "/types/add",
  put: "/types/update",
  delete: "/types/delete",
};

export const APPLIQUES_API = {
  get: "/public/appliques",
};

export const TYPEDETAIL_API = {
  get: "/typedetail/get",
  post: "/typedetail/add",
  put: "/typedetail/update",
  delete: "/typedetail/delete",
};

export const STITCHING_TYPE_API = {
  get: "/public/stitchingtype",
  post: "/stitchingtype/add",
  put: "/stitchingtype/update",
  delete: "/stitchingtype/delete",
};

export const LETTER_PLACEMENT_API = {
  get: "/lettersplacement/get",
  post: "/lettersplacement/add",
  put: "/lettersplacement/update",
  delete: "/lettersplacement/delete",
};

export const LETTER_SIZE_API = {
  get: "/greekLetterSize/get",
  post: "/greekLetterSize/add",
  put: "/greekLetterSize/update",
  delete: "/greekLetterSize/delete",
};

export const EMBROIDARY_API = {
  get: "/public/embroidary",
  post: "/embroidarydesign/add",
  put: "/embroidarydesign/update",
  delete: "/embroidarydesign/delete",
};

export const EMBROIDARY_DESIGN_API = {
  get: "/embroidarydesign/get",
  post: "/embroidarydesign/add",
  put: "/embroidarydesign/update",
  delete: "/embroidarydesign/delete",
};

export const EMBROIDARY_LOCATION_API = {
  get: "/public/embroidaryLocation",
};

export const SIZES_API = {
  get: "/size/get",
  post: "/size/add",
  put: "/size/update",
  delete: "/size/delete",
};

export const NOTICE_API = {
  get: "/public/notice",
  post: "/notice/add",
};

export const USER_API = {
  get: "/user/get",
  post: "/user/post",
  put: "/user/update",
  delete: "/user/delete",
};

export const USER_ADDRESS_API = {
  get: "/shippingaddress/get"
}
