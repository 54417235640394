import { createContext, useState } from "react";

const LoaderContext = createContext({
  loader: null,
  showLoader: function (loaderData) {},
  hideLoader: function () {},
});

export function LoaderContextProvider(props) {
  const [activeLoader, setActiveLoader] = useState();

  const showLoaderHandler = (loaderData) => {
    setActiveLoader(true);
  };
  const hideLoaderHandler = () => {
    setActiveLoader(false);
  };

  const context = {
    loader: activeLoader,
    showLoader: showLoaderHandler,
    hideLoader: hideLoaderHandler,
  };
  return (
    <LoaderContext.Provider value={context}>
      {props.children}
    </LoaderContext.Provider>
  );
}

export default LoaderContext;
