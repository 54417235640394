import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  RadioGroup,
  Typography,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "../../utils/axios";
import NotificationContext from "../../store/notification-context";
import ImagePicker from "../ui/image-picker";
import { GET, NOTICE_API, POST } from "../../api/config";
import { useGet } from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";

function AddAnnouncement() {
  const [noticeData, setNoticeData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [image, setImage] = useState(null);
  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(NOTICE_API[GET], {
    apiCall: "onload",
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setNoticeData(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    if (Object.keys(noticeData).length > 0) {
      console.log(noticeData, "noticeData");
      setImage(noticeData.file);
      setValue("isVisible", noticeData.isVisible.toString());
      setDisabled(true);
    }
  }, [noticeData]);

  const onSubmit = async (data) => {
    const image = data.image[0];
    const formData = new FormData();
    formData.append("file", image);
    formData.append("isVisible", data.isVisible);
    console.log(image, "image", formData);
    axios({
      async: true,
      url: NOTICE_API[POST],
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: formData,
    }).then((response) => {
      console.log(response, "response");
      refreshGetData();
      setDisabled(true);
      notificationCtx.showNotification({
        title: response.data.message,
        status: "success",
      });
    });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Annoucement"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.update == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setDisabled(false);
                  }}
                >
                  Edit Annoucement
                </Button>
              )
            }
          />
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={7}>
                <Grid item xs={3}>
                  <Typography variant="body1">Image</Typography>
                </Grid>
                <Grid item xs={9}>
                  <ImagePicker
                    {...{ register, errors }}
                    image={image}
                    value="image"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Visible</Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <label>
                        <div>
                          <input
                            disabled={disabled}
                            type="radio"
                            value="true"
                            {...register("isVisible")}
                          />
                          <span>True</span>
                        </div>
                      </label>
                      <label>
                        <div>
                          <input
                            disabled={disabled}
                            type="radio"
                            value="false"
                            {...register("isVisible")}
                          />
                          <span>False</span>
                        </div>
                      </label>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!disabled && (
                  <Grid item xs={12}>
                    <div
                      className="align-right"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Button type="submit" color="primary" variant="contained">
                        Update Annoucement
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AddAnnouncement;
