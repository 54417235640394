import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import { useGet, usePost, usePut, useDelete } from "../../hooks/useFetch";
import {
  TYPEDETAIL_API,
  GET,
  POST,
  PUT,
  DELETE,
  TYPES_API,
} from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function StitchingType() {
  const [typeDetails, setTypeDetails] = useState([]);
  const [types, setTypes] = useState([]);
  const [typeIds, setTypeIds] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    TYPEDETAIL_API[GET],
    {
      apiCall: "onload",
    }
  );

  const { data: getTypeData } = useGet(TYPES_API[GET], {
    apiCall: "onload",
  });
  const { refresh: postData } = usePost(TYPEDETAIL_API[POST]);
  const { refresh: putData } = usePut(TYPEDETAIL_API[PUT]);
  const { refresh: delData } = useDelete(TYPEDETAIL_API[DELETE]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setTypeDetails(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    if (getTypeData) {
      setTypes(getTypeData.data || []);
    }
  }, [getTypeData]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      sortable: true,
    },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return (<span>{(params.value) ? "Yes" : "No"}</span>)
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Type Detail";
    } else if (action === "add") {
      return "Add Type Detail";
    } else {
      return "Delete Type Detail";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const editRowData = (params) => {
    const { name, active, typeId } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("name", name);
    setTypeIds(typeId);
  };

  const onSubmit = (data) => {
    data.typeId = typeIds;
    if (action === "add") {
      postData({ data }, { refreshGetData, closeModal });
    } else {
      data = {
        ...data,
        _id: rowData._id,
      };
      putData({ data }, { refreshGetData, closeModal });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  const typesHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTypeIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Type Details"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Type Detail
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={typeDetails}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={55}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Types
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={typeIds}
                      onChange={typesHandleChange}
                      input={<OutlinedInput label="Types" />}
                      renderValue={(selected) =>
                        selected.map((select) => (
                          <Chip
                            key={select}
                            label={types?.find((e) => e._id === select).name}
                          />
                        ))
                      }
                      MenuProps={MenuProps}
                    >
                      {types.map((type) => (
                        <MenuItem key={type._id} value={type._id}>
                          <Checkbox checked={typeIds.includes(type._id)} />
                          <ListItemText primary={type.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default StitchingType;
