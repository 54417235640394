import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, List, Typography } from "@mui/material";

// project imports
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";

import {
  getPermissions,
  isMaster,
} from "../../../../../components/utils/common";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const checkItemExists = (menu) => {
  switch (true) {
    case menu.type == "item":
      permissionDataExists = permissions.filter((itm) => {
        //console.log("/dashboard" + itm.basePath + " == " + menu.url);
        return (
          ("/dashboard" + itm.basePath).toLowerCase() == menu.url.toLowerCase()
        );
      });
      //console.log(permissionDataExists.length);
      if (
        menu.id != "default" &&
        !isMasterUser &&
        permissionDataExists.length == 0
      ) {
        return false;
      }
      break;
    case menu.type == "collapse":
      /* get all children */
      /*let collapseChildren = menu.children.map((v) => {
        console.log("v : ", v);
        permissionDataExists = permissions.filter((itm) => {
          console.log(
            "/dashboard" + itm.basePath + " == " + v.url.toLowerCase()
          );
          return (
            ("/dashboard" + itm.basePath).toLowerCase() == v.url.toLowerCase()
          );
        });
        //console.log(permissionDataExists.length);
        if (
          v.id != "default" &&
          !isMasterUser &&
          permissionDataExists.length == 0
        ) {
          return false;
        }
      });

      if (collapseChildren.length == 0) return false;*/
      break;
  }
};

const NavGroup = ({ item }) => {
  const theme = useTheme();
  //const MenuAccordianCtx = useContext(MenuAccordianContext);

  //const activeMenuAccordian = MenuAccordianCtx.visibility;

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    //console.log(menu);
    //console.info(item);
    const permissions = getPermissions();
    const isMasterUser = isMaster() == "true" ? true : false;
    //console.log("isMasterUser : ", isMasterUser);
    let permissionDataExists = [];
    //console.log(getPermissions());
    switch (true) {
      case menu.type == "item":
        permissionDataExists = permissions.filter((itm) => {
          //console.log("/dashboard" + itm.basePath + " == " + menu.url);
          return (
            ("/dashboard" + itm.basePath).toLowerCase() ==
            menu.url.toLowerCase()
          );
        });
        //console.log(permissionDataExists.length);
        if (
          menu.id != "default" &&
          !isMasterUser &&
          permissionDataExists.length == 0
        ) {
          return false;
        }
        break;
      case menu.type == "collapse":
      /* get all children */
      /*let collapseChildren = menu.children.map((v) => {
          console.log("v : ", v);
          permissionDataExists = permissions.filter((itm) => {
            console.log(
              "/dashboard" + itm.basePath + " == " + v.url.toLowerCase()
            );
            return (
              ("/dashboard" + itm.basePath).toLowerCase() == v.url.toLowerCase()
            );
          });
          //console.log(permissionDataExists.length);
          if (
            v.id != "default" &&
            !isMasterUser &&
            permissionDataExists.length == 0
          ) {
            return false;
          }
        });

        if (collapseChildren.length == 0) return false;
        break;*/
    }

    switch (menu.type) {
      case "collapse":
        return (
          <NavCollapse key={menu.id} parent={item} menu={menu} level={1} />
        );
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.menuCaption }}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
