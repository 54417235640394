import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  RadioGroup,
  FormLabel,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import axios from "../../utils/axios";
import { useGet, useDelete } from "../../hooks/useFetch";
import { SPECIAL_OFFER_API, GET, POST, PUT, DELETE } from "../../api/config";
import NotificationContext from "../../store/notification-context";
import ImagePicker from "../ui/image-picker";
import { appendImage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { insertLineBreaks } from "../../utils/helper";
import InfoIcon from '@mui/icons-material/Info';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const appliedOnArr = [
  {
    _id: "1",
    name: "All",
    value: "productlist",
  },
  {
    _id: "2",
    name: "Category",
    value: "category",
  },
  {
    _id: "3",
    name: "Subcategory",
    value: "subcategory",
  },
  {
    _id: "4",
    name: "Products",
    value: "productlist",
  },
];

function SpecialOffer() {
  const [specialOffers, setSpecialOffers] = useState([]);
  const [ids, setIds] = useState([]);
  const [productsArr, setProductsArr] = useState([]);
  const [image, setImage] = useState(null);
  const [appOnValue, setAppOnValue] = useState("");
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    SPECIAL_OFFER_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { data: getProductsData, refresh: refreshGetProductsData } = useGet(
    SPECIAL_OFFER_API[GET]
  );
  const { refresh: delData } = useDelete(SPECIAL_OFFER_API[DELETE]);

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setSpecialOffers(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    if (getProductsData) {
      setProductsArr(getProductsData.data || []);
    }
  }, [getProductsData]);

  useEffect(() => {
    if (appOnValue) {
      refreshGetProductsData();

      // fetch(`/public/${appOnValue}`).then((response) => {
      //   setProductsArr(response.data);
      // });
    }
  }, [appOnValue]);

  const appliedOnHandleChange = (event) => {
    setAppOnValue(event.target.value);
  };

  const productsHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event.target.value, event.target);
    setIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const editRowData = (params) => {
    const {
      name,
      couponCode,
      description,
      image,
      termAndCondition,
      discount,
      discountType,
      link,
      active,
      appliedOn,
      ids,
    } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");

    setImage(image);
    setIds(ids);
    setAppOnValue(appliedOn);
    setValue("name", name);
    setValue("couponCode", couponCode);
    setValue("description", description);
    setValue("termAndCondition", termAndCondition);
    setValue("discount", discount);
    setValue("discountType", discountType);
    setValue("link", link);
    setValue("active", active.toString());
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      }
    },
    { field: "appliedOn", headerName: "Applied On", width: 100 },
    { field: "discount", headerName: "Discount", width: 100 },
    { field: "discountType", headerName: "Discount Type", width: 100 },
    { field: "couponCode", headerName: "Coupon Code", width: 100 },
    {
      field: "termAndCondition",
      headerName: "Term & Condition",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        return (<span>{(params?.value) ? "Yes" : "No"}</span>)
      }
    },
    {
      field: "link",
      headerName: "Link",
      width: 200,
      renderCell: (params) => {
        const linkText = params.value || '';
        const textWithLineBreaks = insertLineBreaks(linkText, 25);
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {textWithLineBreaks}
          </div>
        );
      }
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      sortable: true,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const closeModal = () => {
    setModal(false);
  };

  const onSubmit = (data) => {
    if (action === "add") {
      const image = data.image[0];
      const formData = new FormData();
      formData.append("image", image);
      formData.append("appliedOn", appOnValue.toLowerCase());
      ids.forEach((id) => {
        formData.append("ids", id);
      });
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("couponCode", data.couponCode);
      formData.append("termAndCondition", data.termAndCondition);
      formData.append("discount", data.discount);
      formData.append("discountType", data.discountType);
      formData.append("link", data.link);
      formData.append("active", data.active);
      axios({
        async: true,
        url: SPECIAL_OFFER_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    } else {
      const formData = new FormData();
      console.log(data, "data");
      if (typeof data.image === "object" && data.image.length > 0) {
        const image = data.image[0];
        formData.append("image", image);
      }
      formData.append("appliedOn", appOnValue.toLowerCase());
      ids.forEach((id) => {
        formData.append("ids", id);
      });
      formData.append("ids", JSON.stringify(ids));
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("couponCode", data.couponCode);
      formData.append("termAndCondition", data.termAndCondition);
      formData.append("discount", data.discount);
      formData.append("discountType", data.discountType);
      formData.append("active", data.active);
      formData.append("_id", rowData._id);
      axios({
        async: true,
        url: SPECIAL_OFFER_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    }
  };

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update SpecialOffer";
    } else if (action === "add") {
      return "Add SpecialOffer";
    } else {
      return "Delete SpecialOffer";
    }
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Manage Offers"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add SpecialOffer
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={specialOffers}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={100}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SpecialOffer Name"
                    placeholder="Enter SpecialOffer Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SpecialOffer Description"
                    placeholder="Enter SpecialOffer Description"
                    {...register("description", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                  {errors.description && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Offer
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <label>
                        <div>
                          <input
                            type="radio"
                            value="true"
                            {...register("active", {
                              required: true,
                            })}
                          />
                          <span>Active</span>
                        </div>
                      </label>
                      <label>
                        <div>
                          <input
                            type="radio"
                            value="false"
                            {...register("active", {
                              required: true,
                            })}
                          />
                          <span>InActive</span>
                        </div>
                      </label>
                    </RadioGroup>
                    {errors.active && (
                      <span className="error-text">
                        This field is required.
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="CouponCode"
                    placeholder="Enter CouponCode"
                    {...register("couponCode", { required: true })}
                  />
                  {errors.couponCode && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="TermAndCondition"
                    placeholder="Enter TermAndCondition"
                    {...register("termAndCondition", {
                      required: true,
                    })}
                  />
                  {errors.termAndCondition && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Discount"
                    placeholder="Enter Discount"
                    {...register("discount", { required: true })}
                  />
                  {errors.discount && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="DiscountType"
                    placeholder="Enter DiscountType"
                    {...register("discountType", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                  {errors.discountType && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-appliedOn">Applied On</InputLabel>
                    <Select
                      labelId="select-appliedOn"
                      id="select-appliedOn"
                      value={appOnValue}
                      label="appliedOn"
                      onChange={appliedOnHandleChange}
                    >
                      {appliedOnArr.map((appOn) => (
                        <MenuItem key={appOn.name} value={appOn.value}>
                          {appOn.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Products
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={ids}
                      onChange={productsHandleChange}
                      input={<OutlinedInput label="Products" />}
                      renderValue={(selected) =>
                        selected.map((select) => (
                          <Chip
                            key={select}
                            label={
                              productsArr?.find((e) => e._id === select)?.name
                            }
                          />
                        ))
                      }
                      MenuProps={MenuProps}
                    >
                      {productsArr.map((product) => (
                        <MenuItem key={product._id} value={product._id}>
                          <Checkbox checked={ids.includes(product._id)} />
                          <ListItemText primary={product.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Link"
                    placeholder="Enter Link"
                    {...register("link", { required: true })}
                  />
                  {errors.link && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 696x340 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default SpecialOffer;
