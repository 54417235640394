import { BASE_URL } from "../api/config";
import parse from 'html-react-parser'

export function appendImage(imgPath) {
  if (imgPath && typeof imgPath === "string") {
    return imgPath.indexOf("/") > -1 ? imgPath : BASE_URL + imgPath;
  }
  return imgPath || null;
}

export function formatPhoneNumberToUS(phoneNumber) {
  if (phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }
}

export function insertLineBreaks(text, maxCharsPerLine) {
  if (!text || text.length <= maxCharsPerLine) {
    return text;
  }

  const regex = new RegExp(`.{1,${maxCharsPerLine}}`, 'g');
  return text.match(regex).join('\n');
}

export function formatPrice(price, needDollerSign = true) {
  return (price.toString().indexOf("$") === -1 && needDollerSign)?"$ " + parseFloat(price).toFixed(2):parseFloat(price).toFixed(2);
}

export function pascalCase(str){
  return str.toString().replace(/\w+/g,function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}

export function wordWrap(str, maxWidth) {
  var newLineStr = "<br/>", done = false, res = '', found = false;
  while (str.length > maxWidth) {                 
      found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
          if (testWhite(str.charAt(i))) {
              res = res + [str.slice(0, i), newLineStr].join('');
              str = str.slice(i + 1);
              found = true;
              break;
          }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
          res += [str.slice(0, maxWidth), newLineStr].join('');
          str = str.slice(maxWidth);
      }

  }

  return parse(res + str);
}

function testWhite(x) {
  var white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

function str2dom(str) {
  var d = document.createElement('div'),
      df = document.createDocumentFragment();
  d.innerHTML = str;
  while (d.childNodes.length)
      df.appendChild(d.childNodes[0]);
  return df;
}

export function formatDate(date) {
  date = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-US', {
      /*weekday: 'long',*/
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      /*hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false     // setting 24 hour format */
  });
  const formattedDate = formatter.format(date);
  //console.log(formattedDate);
  return formattedDate;
}