import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import { useGet, usePost, usePut, useDelete } from "../../hooks/useFetch";
import {
  BACKGROUND_COLOR_API,
  BACKGROUND_MATERIAL_API,
  GET,
  POST,
  PUT,
  DELETE,
} from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { formatPrice } from "../../utils/helper";

const currencyArr = [
  {
    _id: "1",
    name: "$",
  },
];

function BackgroundColor() {
  const [cValue, setCValue] = useState("");
  const [fmValue, setFmValue] = useState("");
  const [backgroundColorList, setbackgroundColorList] = useState([]);
  const [backgroundmaterialList, setBackgroundmaterialList] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: backgroundColorData, refresh: refreshBCList } = useGet(
    BACKGROUND_COLOR_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { data: backgroundMaterialData } = useGet(
    BACKGROUND_MATERIAL_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { refresh: postData } = usePost(BACKGROUND_COLOR_API[POST]);
  const { refresh: putData } = usePut(BACKGROUND_COLOR_API[PUT]);
  const { refresh: delData } = useDelete(BACKGROUND_COLOR_API[DELETE]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (backgroundMaterialData) {
      setBackgroundmaterialList(backgroundMaterialData.data || []);
    }
  }, [backgroundMaterialData]);

  useEffect(() => {
    if (backgroundColorData) {
      setbackgroundColorList(backgroundColorData.data || []);
    }
  }, [backgroundColorData]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "value",
      headerName: "Value",
      width: 150,
      sortable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return <span>{formatPrice(params.value)}</span>
      }
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Background Color";
    } else if (action === "add") {
      return "Add Background Color";
    } else {
      return "Delete Background Color";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const currencyHandleChange = (event) => {
    setCValue(event.target.value);
  };

  const backgroundMaterialHandleChange = (event) => {
    setFmValue(event.target.value);
  };

  const editRowData = (params) => {
    const { name, value, price, currency, backgroundMaterialId } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setCValue(currency);
    setValue("name", name);
    setValue("value", value);
    setValue("price", price);
    setFmValue(backgroundMaterialId);
  };

  const onSubmit = (data) => {
    data = {
      ...data,
      currency: cValue,
      backgroundMaterialId: fmValue,
    };
    if (action === "add") {
      postData({ data }, { refreshBCList, closeModal });
    } else {
      data = {
        ...data,
        _id: rowData._id,
      };
      putData({ data }, { refreshBCList, closeModal });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshBCList, closeModal });
  };

  const changeColorCode = (e) => {
    setValue('value', e.target.value.toUpperCase());
  };

  const changeColorName = (e) => {
    const inputValue = e.target.value;
    setValue('name', inputValue.charAt(0).toUpperCase() + inputValue.slice(1));
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Background Color Pricing"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Background Color
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={backgroundColorList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={55}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-backgroundMaterial">
                      Select Background Material
                    </InputLabel>
                    <Select
                      labelId="select-backgroundMaterial"
                      id="select-backgroundMaterial"
                      value={fmValue}
                      label="backgroundMaterial"
                      onChange={backgroundMaterialHandleChange}
                    >
                      {backgroundmaterialList.map((fg) => (
                        <MenuItem key={fg._id} value={fg._id}>
                          {fg.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter Name"
                    inputProps={{
                      onChange: changeColorName
                    }}
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="value"
                    placeholder="Please enter HEX code"
                      inputProps={{
                        onChange: changeColorCode,
                        maxLength: 7,
                        defaultValue: "#"
                      }}
                    {...register("value", { required: true })}
                  />
                  {errors.value && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Price"
                    placeholder="Enter Price"
                    {...register("price", { required: true })}
                  />
                  {errors.price && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-role">Select Currency</InputLabel>
                    <Select
                      labelId="select-currency"
                      id="select-currency"
                      value={cValue}
                      label="currency"
                      onChange={currencyHandleChange}
                    >
                      {currencyArr.map((currency) => (
                        <MenuItem key={currency.name} value={currency.name}>
                          {currency.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default BackgroundColor;
