import axios from "axios";

export const axiosInstance = axios.create({
  // baseURL: "http://3.109.209.115:3000",
  baseURL: "https://admin.favorsbythebay.com/apis",
});

const getToken = () => {
  return sessionStorage.getItem("token");
};

axiosInstance.interceptors.request.use(
  async (config) => {
    config = {
      ...config,
      headers: {
        Authorization: `jwt ${getToken()}`,
      },
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
