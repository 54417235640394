import { BrowserRouter } from "react-router-dom";
// routes
import Router from "../routes";
// theme
import ThemeProvider from "../theme";
import { NotificationContextProvider } from "../store/notification-context";
import { LoaderContextProvider } from "../store/loader-context";

import './App.css';

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <NotificationContextProvider>
          <LoaderContextProvider>
            <Router />
          </LoaderContextProvider>
        </NotificationContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
