export default function componentStyleOverrides(theme) {
    return {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.primary?.dark,
                    },
                },
            }
        },
        // MuiPaper: {
        //     defaultProps: {
        //         elevation: 0
        //     },
        //     styleOverrides: {
        //         root: {
        //             backgroundImage: 'none',
        //             '&.MuiAlert-standard': {
        //                 color: '#fff',
        //                 backgroundColor: '#d32f2f',
        //                 fontWeight: "500",
        //                 fontSize: "0.875rem"
        //             },
        //         },
        //     }
        // },
    };
}
