import { useEffect, useContext } from "react";
import NotificationContext from "../store/notification-context";

function ErrorPage({ children }) {
  const notificationCtx = useContext(NotificationContext);

  useEffect(() => {
    notificationCtx.showNotification({
      title: "Unauthorised access | Token expired.",
      status: "error",
    });
  }, []);
  
  return <h1>{children}</h1>
}

export default ErrorPage;
