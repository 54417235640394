// component
import SvgColor from "../../../components/ui/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const dashboard = {
  id: "dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icon("ic_user"),
    },
  ],
};

const pages = {
  id: "pages",
  title: "",
  type: "group",
  children: [
    {
      id: "access-management",
      title: "Module Management",
      type: "collapse",
      children: [
        {
          id: "manage-module",
          title: "Module List",
          type: "item",
          url: "/dashboard/module",
        },
      ],
    },
    {
      id: "user-management",
      title: "User Management",
      type: "collapse",
      children: [
        {
          id: "create-role",
          title: "Manage Roles",
          type: "item",
          url: "/dashboard/role",
        },
        {
          id: "manage-roles",
          title: "Manage Permissions",
          type: "item",
          url: "/dashboard/assignpermission",
        },
        {
          id: "manage-users",
          title: "Manage Users",
          type: "item",
          url: "/dashboard/users",
        },
      ],
    },
    {
      id: "configurations",
      title: "Configurations",
      type: "collapse",
      children: [
        {
          id: "annoucements",
          title: "Announcements",
          type: "item",
          url: "/dashboard/announcement",
        },
        {
          id: "landing-page",
          title: "Landing Page",
          type: "collapse",
          children: [
            {
              id: "slider",
              title: "Sliders",
              type: "item",
              url: "/dashboard/LandingPageFirstSection",
            },
            {
              id: "marquee",
              title: "Ticker Tape",
              type: "item",
              url: "/dashboard/marquee",
            },
            {
              id: "landingPageMiddleSection",
              title: "Quick Links",
              type: "item",
              url: "/dashboard/LandingPageMiddleSection",
            },
            {
              id: "offers",
              title: "Offers",
              type: "item",
              url: "/dashboard/specialoffer",
            },
          ],
        },
        // {
        //     id: 'footer',
        //     title: 'Footer',
        //     type: 'item',
        //     url: '/dashboard/footer',
        // }
      ],
    },
    {
      id: "product-management",
      title: "Product Management",
      type: "collapse",
      children: [
        {
          id: "categories",
          title: "Categories",
          type: "item",
          url: "/dashboard/category",
        },
        {
          id: "sub-categories",
          title: "Sub-Categories",
          type: "item",
          url: "/dashboard/subcategory",
        },
        // {
        //   id: "create-product",
        //   title: "Create Product",
        //   type: "item",
        //   url: "/dashboard/createProduct",
        // },
        {
          id: "products",
          title: "Products",
          type: "item",
          url: "/dashboard/productList",
        },
      ],
    },
    {
      id: "order-management",
      title: "Order-Management",
      type: "collapse",
      children: [
        {
          id: "list-of-orders",
          title: "Manage Orders",
          type: "item",
          url: "/dashboard/orderlist",
        },
      ],
    },
    {
      id: "masters",
      title: "Masters",
      type: "collapse",
      children: [
        {
          id: "fonts",
          title: "Fonts",
          type: "item",
          url: "/dashboard/fontstyle",
        },
        {
          id: "font-colors",
          title: "Product Colors",
          type: "item",
          url: "/dashboard/fontcolor",
        },
        {
          id: "colors",
          title: "Material Colors",
          type: "item",
          url: "/dashboard/color",
        },
        {
          id: "applique",
          title: "Applique",
          type: "collapse",
          children: [
            {
              id: "foreground-material",
              title: "Foreground Material Pricing",
              type: "item",
              url: "/dashboard/foregroundmaterial",
            },
            {
              id: "foreground-color",
              title: "Foreground Color Pricing",
              type: "item",
              url: "/dashboard/foregroundcolor",
            },
            {
              id: "background-material",
              title: "Background Material Pricing",
              type: "item",
              url: "/dashboard/backgroundmaterial",
            },
            {
              id: "background-color",
              title: "Background Color Pricing",
              type: "item",
              url: "/dashboard/backgroundcolor",
            },
            {
              id: "thread-to-match",
              title: "Thread Color Pricing",
              type: "item",
              url: "/dashboard/threadtomatch",
            },
            {
              id: "types",
              title: "Locations",
              type: "item",
              url: "/dashboard/types",
            },
            {
              id: "typeDetail",
              title: "Type Detail",
              type: "item",
              url: "/dashboard/typedetail",
            },
            {
              id: "stitchingType",
              title: "Stitching Types",
              type: "item",
              url: "/dashboard/stitchingtype",
            },
            {
              id: "letterPlacement",
              title: "Letter Placements",
              type: "item",
              url: "/dashboard/letterplacement",
            },
            {
              id: "letterSize",
              title: "Letter Sizes",
              type: "item",
              url: "/dashboard/lettersize",
            },
          ],
        },
        {
          id: "embroidery",
          title: "Embroidery",
          type: "collapse",
          children: [
            {
              id: "embroidery",
              title: "Crest and Images",
              type: "item",
              url: "/dashboard/embroidery",
            },
            // {
            //   id: "embroidery-b",
            //   title: "Embroidery B",
            //   type: "item",
            //   url: "/dashboard/embroideryB",
            // },
          ],
        },
      ],
    },
    {
      id: "settings",
      title: "Settings",
      type: "collapse",
      children: [
        {
          id: "pricing",
          title: "Pricing",
          type: "item",
          url: "/dashboard/pricing",
        },
      ],
    },
    {
      id: "reports",
      title: "Reports",
      type: "collapse",
      children: [
        {
          id: "customer-report",
          title: "Customer Report",
          type: "item",
          url: "/dashboard/customerReport",
        },
        {
          id: "log-report",
          title: "Log Report",
          type: "item",
          url: "/dashboard/logReport",
        },
        {
          id: "system-usage-report",
          title: "System Usage Report",
          type: "item",
          url: "/dashboard/systemReport",
        },
        {
          id: "billing-report",
          title: "Billing Report",
          type: "item",
          url: "/dashboard/billingReport",
        },
        {
          id: "user-contact-report",
          title: "User Contact Report",
          type: "item",
          url: "/dashboard/userContactReport",
        },
      ],
    },
  ],
};

const menuItems = {
  items: [dashboard, pages],
};

export default menuItems;
