import React from 'react';
import GeneralContent from '../Dashboard/GeneralContent';

function NewDashboard() {
	return (
		<React.Fragment>
			<div>
				<div className="container-fluid">
					<div className="d-sm-flex aligns-items-center justify-content-between mb-4">
						<h1 className="h3 mb-0 text-gray-800">App Dashboard</h1>
					</div>
					<GeneralContent />
				</div>
			</div>
		</React.Fragment>
	)
}

export default NewDashboard;