import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  Grid,
  TextField,
  Button,
  CardContent,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import fetch from "../../utils/fetch";

import { useGet, usePut } from "../../hooks/useFetch";
import { MARQUEE_API, GET, PUT } from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";

function Marquee() {
  const [action, setAction] = useState("view");
  const [disabled, setDisabled] = useState(true);
  const [marqueeData, setMarqueeData] = useState();

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(MARQUEE_API[GET], {
    apiCall: "onload",
  });
  const { data: getPutData, refresh: putData } = usePut(MARQUEE_API[PUT]);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setMarqueeData(getData.data || []);
      const { name, active } = getData.data;
      setValue("name", name);
      setDisabled(true);
      setValue("active", active);
    }
  }, [getData]);

  useEffect(() => {
    if (getPutData) {
      setDisabled(true);
    }
  }, [getPutData]);

  const onSubmit = (data) => {
    data = {
      "data": {
        name: data.name,
        _id: marqueeData._id,
      }
    };
    putData(data, { refreshGetData });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Manage Ticker"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.update == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setAction("edit");
                    setDisabled(false);
                  }}
                >
                  Edit Ticker
                </Button>
              )
            }
          />
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={7}>
                <Grid item xs={3}>
                  {/* <Typography variant="body1">Marquee</Typography> */}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Please enter text here"
                    disabled={disabled}
                    multiline={true}
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                {!disabled && (
                  <Grid item xs={12}>
                    <div
                      className="align-right"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Button type="submit" color="primary" variant="contained">
                        Update Ticker
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Marquee;
