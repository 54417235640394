import { useEffect, useReducer, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../store/notification-context";
import axios from "../utils/axios";
import LoaderContext from "../store/loader-context";

// const BASE_URL = "http://3.109.209.115:3000";
//const BASE_URL = "http://localhost:5000";
const BASE_URL = "https://admin.favorsbythebay.com/apis";

const getToken = () => {
  return sessionStorage.getItem("token");
};

export function useGet(url, fetchOptions) {
  return useFetch(url, { method: "GET" }, fetchOptions);
}

export function usePost(url) {
  return useFetch(url, { method: "POST" });
}

export function usePut(url) {
  return useFetch(url, { method: "PUT" });
}

export function useDelete(url) {
  return useFetch(url, { method: "DELETE" });
}

function useFetch(url, options, { apiCall = "" } = {}) {
  const cache = useRef({});
  const notificationCtx = useContext(NotificationContext);
  const loaderCtx = useContext(LoaderContext);
  const navigate = useNavigate();

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef(false);

  const initialState = {
    error: undefined,
    data: undefined,
    fetchRequest: apiCall === "onload" ? true : false,
    isLoading: apiCall === "onload" ? true : false,
  };

  // Keep state logic separated
  const fetchReducer = (state, action) => {
    switch (action.type) {
      case "loading":
        return { ...initialState, isLoading: action.payload };
      case "fetched":
        return { ...initialState, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const fetchData = async (apiData, functionCalls) => {
    dispatch({ type: "loading" });
    loaderCtx.showLoader();

    // If a cache exists for this url, return it
    if (cache.current[url]) {
      dispatch({ type: "fetched", payload: cache.current[url] });
      return;
    }

    if (apiData) {
      if (apiData.data) {
        options.body = JSON.stringify(apiData.data);
      }
      if (apiData.url) {
        url += apiData.url;
      }
    }
    try {
      url = BASE_URL + url;
      options = {
        ...options,
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${getToken()}`,
          source: sessionStorage.getItem("current_module_id"),
        },
      };

      let response;
      try {
        response = await window.fetch(url, options);
      } catch (err) {
        notificationCtx.showNotification({
          title: err.message,
          status: "error",
        });
        dispatch({ type: "error", payload: err });
        return;
      }

      if (!response.ok) {
        if (response.status === 401) {
          notificationCtx.showNotification({
            title: "Unauthorized token",
            status: "error",
          });
          setTimeout(() => {
            navigate("/");
            notificationCtx.hideNotification();
          }, 1000);
          return;
        } else {
          const errData = await response.json();
          notificationCtx.showNotification({
            title: errData.error,
            status: "error",
          });
          dispatch({ type: "error", payload: errData }); //new Error(response.statusText)
          loaderCtx.hideLoader();
          return;
        }
      }

      const data = await response.json();
      cache.current[url] = data;
      if (cancelRequest.current) return;

      if (data.message && options.method !== "GET") {
        notificationCtx.showNotification({
          title: data.message,
          status: "success",
        });
        for (let func in functionCalls) {
          functionCalls[func]();
        }
      }

      dispatch({ type: "fetched", payload: data });
      dispatch({ type: "loading", payload: false });
      loaderCtx.hideLoader();
    } catch (error) {
      if (cancelRequest.current) return;
      notificationCtx.showNotification({
        title: error,
        status: "error",
      });
      dispatch({ type: "error", payload: error });
      loaderCtx.hideLoader();
    }
  };

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return;

    cancelRequest.current = false;

    if (state.fetchRequest) fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
  }, [url, state.fetchRequest]);

  const refresh = (apiData, functionCalls) => {
    fetchData(apiData, functionCalls);
  };

  return { ...state, refresh };
}

export const addImage = (img) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const image = img[0];
      const formData = new FormData();
      formData.append("file", image);
      axios({
        async: true,
        url: "/file/add",
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.data);
          }
          console.log(response, "response");
        })
        .catch((err) => {
          reject(err);
        });
    }, 1000);
  });
};

export default useFetch;
