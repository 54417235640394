import { useContext } from "react";

import { Snackbar, Alert } from "@mui/material";
import NotificationContext from "../../../store/notification-context";

function Notification(props) {
  const notificationCtx = useContext(NotificationContext);
  const { title, status } = props;

  return (
    <Snackbar open={true} onClose={notificationCtx.hideNotification} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={notificationCtx.hideNotification} severity={status} sx={{ width: "100%" }}>
        {title}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
