import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { CircularProgress, useMediaQuery } from "@mui/material";
//
import Header from "./header";
import Sidebar from "./sidebar";
import NotificationContext from "../../store/notification-context";
import Notification from "../../components/ui/notification";
import LoaderContext from "../../store/loader-context";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  borderRadius: "20px",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  background: theme.palette.background.neutral,
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledDiv = styled("div")(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: "10px",
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  // const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const NotificationCtx = useContext(NotificationContext);
  const LoaderCtx = useContext(LoaderContext);

  const activeNotification = NotificationCtx.notification;
  const activeLoader = LoaderCtx.loader;
  // const Open = !matchDownMd || open;
  const isDrawerOpen = open;

  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <StyledRoot>
      {/* <Header onOpenNav={() => setOpen(true)} /> */}
      <Header onOpenNav={handleDrawerToggle} />

      {/* drawer */}
      {/* <Sidebar drawerOpen={Open} drawerToggle={() => setOpen(false)} /> */}
      {isDrawerOpen && <Sidebar drawerOpen={isDrawerOpen} drawerToggle={handleDrawerToggle} />}

      <Main>
        <StyledDiv>
          <Outlet />
        </StyledDiv>
      </Main>
      {activeNotification && (
        <Notification
          title={activeNotification.title}
          status={activeNotification.status}
        />
      )}
      {activeLoader && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </StyledRoot>
  );
}
