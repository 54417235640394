import { useContext } from "react";
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../../components/ui/logo';
import NotificationContext from '../../store/notification-context';
import Notification from "../../components/ui/notification";

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const NotificationCtx = useContext(NotificationContext);
  const activeNotification = NotificationCtx.notification;
  return (
    <>
      <StyledHeader>
        <Logo />
      </StyledHeader>
      {activeNotification && (
        <Notification
          title={activeNotification.title}
          status={activeNotification.status}
        />
      )}
      <Outlet />
    </>
  );
}
