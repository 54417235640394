import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import axios from "../../utils/axios";
import {
  useGet,
  usePost,
  usePut,
  useDelete,
  addImage,
} from "../../hooks/useFetch";
import { LANDING_PAGE_API, GET, POST, PUT, DELETE } from "../../api/config";
import NotificationContext from "../../store/notification-context";
import ImagePicker from "../ui/image-picker";
import { appendImage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import InfoIcon from '@mui/icons-material/Info';

const positionArr = [
  { _id: 1, position: "leftTop" },
  { _id: 2, position: "centerTop" },
  { _id: 3, position: "rightTop" },
  { _id: 4, position: "leftMiddle" },
  { _id: 5, position: "centerMiddle" },
  { _id: 6, position: "rightMiddle" },
  { _id: 7, position: "leftBottom" },
  { _id: 8, position: "middleBottom" },
  { _id: 9, position: "rightBottom" },
];

function LPSlider() {
  const [pValue, setPValue] = useState("");
  const [image, setImage] = useState(null);
  const [action, setAction] = useState("view");
  const [sliders, setSliders] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    LANDING_PAGE_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { refresh: postData } = usePost(LANDING_PAGE_API[POST]);
  const { refresh: delData } = useDelete(LANDING_PAGE_API[DELETE]);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "button",
  });

  useEffect(() => {
    if (getData) {
      setSliders(getData.data || []);
    }
  }, [getData]);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "position",
      headerName: "Position",
      width: 100,
    },
    {
      field: "active",
      headerName: "Active",
      width: 100,
    },
    {
      field: "button",
      headerName: "Button",
      width: 250,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) =>
        params.value.length > 0
          ? params.value.map((button) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <p style={{ margin: 0 }}>
                    <strong>Button Name-&nbsp;</strong>
                  </p>
                  <p style={{ margin: 0 }}>{button.name}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ margin: 0 }}>
                    <strong>Button Url-&nbsp;</strong>
                  </p>
                  <p style={{ margin: 0 }}>{button.url}</p>
                </div>
              </div>
            ))
          : "----",
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Sliders";
    } else if (action === "add") {
      return "Add Sliders";
    } else {
      return "Delete Sliders";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const editRowData = (params) => {
    const { title, description, position, active, image, button } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("title", title);
    setValue("description", description);
    setValue("button", button);
    setValue("active", active.toString());
    setImage(image);
    setPValue(position);
  };

  const onSubmit = async (data) => {
    console.log(data, "data");
    if (action === "add") {
      let imageRes = "";
      if(data.image.length > 0){
        imageRes = await addImage(data.image);
      } else {
        imageRes = image;
      }

      data = {
        ...data,
        image: (imageRes instanceof Array && imageRes.length > 0)?imageRes[0]:imageRes,
        position: pValue,
      };
      if(typeof data.button === "undefined"){
        data.button = [];
      }
      postData({ data }, { refreshGetData, closeModal });
    } else {
      /* console.log("data.button : ", data.button);
      if(data.button.length > 0){
        let btnArr = {};
        data.button.map((btn, i) => {
          return btnArr[i] = btn;
        });
        data.button = Object.values(JSON.parse(data.button));
      }
      console.log("data.button : ", data.button); */
      console.log("data.image : ", data.image);
      let imageRes = "";
      if(data.image.length > 0){
        imageRes = await addImage(data.image);
      } else {
        imageRes = image;
      }
      
      /* const formData = new FormData();
      let image;
      if (typeof data.image === "object" && data.image.length > 0) {
        image = data.image[0];
        formData.append("image", image);
      }
      formData.append("description", data.description);
      formData.append("position", pValue);
      formData.append("title", data.title);
      formData.append("active", data.active);
      formData.append("button", [...data.button]);
      formData.append("_id", rowData._id); */
      
      data._id = rowData._id;
      data.image = (imageRes instanceof Array && imageRes.length > 0)?imageRes[0]:imageRes;
      delete data.active;
      if(typeof data.button === "undefined"){
        data.button = [];
      }
      console.log("data : ", data);
      axios({
        async: true,
        url: LANDING_PAGE_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: data,
      }).then((response) => {
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  const positionHandleChange = (event) => {
    setPValue(event.target.value);
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Manage Sliders"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Slider
                </Button>
              )
            }
          />
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={sliders}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={125}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    placeholder="Enter Title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="description"
                    placeholder="Enter Description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        append({ name: "", url: "" });
                      }}
                    >
                      Add Button
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {fields.map((item, index) => {
                    return (
                      <div
                        key={item.name}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <p>{index + 1}</p>
                        <TextField
                          label="Button Name"
                          placeholder="Enter Button Name"
                          {...register(`button.${index}.name`, {
                            required: true,
                          })}
                        />
                        <TextField
                          label="Button Url"
                          placeholder="Enter Button Url"
                          {...register(`button.${index}.url`, {
                            required: true,
                          })}
                        />
                        {/* <Controller
                        render={({ field }) => (
                          <TextField
                            label="Button Url"
                            placeholder="Enter Button Url"
                            {...field}
                          />
                        )}
                        name={`button.${index}.url`}
                        control={control}
                      /> */}
                        <IconButton
                          aria-label="delete"
                          color="outlined"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="select-position">
                        Select Position
                      </InputLabel>
                      <Select
                        labelId="select-position"
                        id="select-position"
                        value={pValue}
                        label="position"
                        onChange={positionHandleChange}
                      >
                        {positionArr.map((pos) => (
                          <MenuItem key={pos.position} value={pos.position}>
                            {pos.position}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {/* <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Offer
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <label>
                      <div>
                        <input
                          type="radio"
                          value="true"
                          {...register("active", {
                            required: true,
                          })}
                        />
                        <span>Active</span>
                      </div>
                    </label>
                    <label>
                      <div>
                        <input
                          type="radio"
                          value="false"
                          {...register("active", {
                            required: true,
                          })}
                        />
                        <span>InActive</span>
                      </div>
                    </label>
                  </RadioGroup>
                  {errors.active && (
                    <span className="error-text">This field is required.</span>
                  )}
                </FormControl> */}
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 1368x607 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default LPSlider;
