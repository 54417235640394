import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import { useGet, useDelete } from "../../hooks/useFetch";
import {
  EMBROIDARY_DESIGN_API,
  GET,
  POST,
  PUT,
  DELETE,
  BASE_URL,
} from "../../api/config";
import axios from "../../utils/axios";
import NotificationContext from "../../store/notification-context";
import { appendImage } from "../../utils/helper";
import ImagePicker from "../ui/image-picker";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import InfoIcon from '@mui/icons-material/Info';

function Embroidery() {
  const [embroidery, setEmbroidery] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState(null);
  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    EMBROIDARY_DESIGN_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { refresh: delData } = useDelete(EMBROIDARY_DESIGN_API[DELETE]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setEmbroidery(getData.data || []);
    }
  }, [getData]);

  const columns = [
    { field: "name", headerName: "Name", width: 300, sortable: true },
    {
      field: "image",
      headerName: "Image",
      width: 300,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Embroidery Location";
    } else if (action === "add") {
      return "Add Embroidery Location";
    } else {
      return "Delete Embroidery Location";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const editRowData = (params) => {
    const { name, image } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("name", name);
    setImage(image);
  };

  const onSubmit = (data) => {
    console.log(data, "data");
    if (action === "add") {
      const image = data.image[0];
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", data.name);
      axios({
        async: true,
        url: EMBROIDARY_DESIGN_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    } else {
      const formData = new FormData();
      if (data.image.length > 0) {
        const image = data.image[0];
        formData.append("image", image);
      }
      formData.append("name", data.name);
      formData.append("_id", rowData._id);
      axios({
        async: true,
        url: EMBROIDARY_DESIGN_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Crest and Images"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add Crest and Images
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={embroidery}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={125}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 1200×1406 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default Embroidery;
