import React, { useState, useEffect } from "react";
import SmallCard from "../Dashboard/SmallCard";

const GeneralContent = () => {
  const [productos, setproductos] = useState([]);
  const [users, setusers] = useState([]);
  const [categories, setcategories] = useState([]);
  //const BASE_URL = "http://localhost:5000";
  // const BASE_URL = "http://3.109.209.115:3000/";
  const BASE_URL = "https://admin.favorsbythebay.com/apis";

  useEffect(() => {
    fetch(BASE_URL + "/api/products")
      .then((response) => response.json())
      .then((data) => {
        setproductos(data);
      });
  }, []);

  useEffect(() => {
    fetch(BASE_URL + "/api/users")
      .then((response) => response.json())
      .then((user) => {
        setusers(user);
      });
  }, []);

  useEffect(() => {
    fetch(BASE_URL + "/api/categories")
      .then((response) => response.json())
      .then((categoria) => {
        setcategories(categoria);
      });
  }, []);

  const datos = [productos, users, categories];

  return (
    <div className="resumen">
      <div className="row">
        {datos.map((dato, i) => (
          <SmallCard {...dato} key={i} />
        ))}
      </div>
    </div>
  );
};

export default GeneralContent;
