import DashboardLayout from "../layouts/dashboard";

import Profile from "../components/Cards/Profile";
import AssignPermission from "../components/Permission/AssignPermission";
import Role from "../components/Role";
import Subcategory from "../components/Subcategory";
import LetterPlacement from "../components/LetterPlacement";
import ContactUserList from "../components/Contact/ContactUserList";
import SpecialOffer from "../components/SpecialOffer";
import Marquee from "../components/Marquee";
import FontStyle from "../components/FontStyle";
import ForegroundMaterial from "../components/ForegroundMaterial";
import ForegroundColor from "../components/ForegroundColor";
import BackgroundMaterial from "../components/BackgroundMaterial";
import BackgroundColor from "../components/BackgroundColor";
import ThreadToMatch from "../components/ThreadToMatch";
import Announcement from "../components/Announcement";
import LPSlider from "../components/LP1stSection";
import LPMiddleSection from "../components/LPMiddleSection";
import FontColor from "../components/FontColor";
import LetterSize from "../components/LetterSize";
import Color from "../components/Color";
import NewDashboard from "../components/NewDashboard";
import TableList from "../components/Cards/TableList";
import Category from "../components/Category";
import ProductList from "../components/ProductList";
import Types from "../components/Types";
import TypeDetail from "../components/TypeDetail";
import StitchingType from "../components/StitchingType";
import Embroidery from "../components/Embroidery";
import RoleModule from "../components/RoleModule";
import User from "../components/User";
import Order from "../components/Order";

const MainRoutes = {
  path: "/dashboard",
  element: <DashboardLayout />,
  children: [
    { path: "", element: <NewDashboard /> },
    { path: "userlist", element: <TableList /> },
    { path: "assignpermission", element: <AssignPermission /> },
    { path: "Profile", element: <Profile /> },
    { path: "role", element: <Role /> },
    { path: "module", element: <RoleModule /> },
    { path: "users", element: <User /> },
    { path: "category", element: <Category /> },
    { path: "subcategory", element: <Subcategory /> },
    { path: "letterPlacement", element: <LetterPlacement /> },
    { path: "contact", element: <ContactUserList /> },
    { path: "specialoffer", element: <SpecialOffer /> },
    { path: "marquee", element: <Marquee /> },
    { path: "fontstyle", element: <FontStyle /> },
    { path: "fontcolor", element: <FontColor /> },
    { path: "foregroundmaterial", element: <ForegroundMaterial /> },
    { path: "foregroundcolor", element: <ForegroundColor /> },
    { path: "backgroundmaterial", element: <BackgroundMaterial /> },
    { path: "backgroundcolor", element: <BackgroundColor /> },
    { path: "threadtomatch", element: <ThreadToMatch /> },
    { path: "announcement", element: <Announcement /> },
    { path: "LandingPageFirstSection", element: <LPSlider /> },
    { path: "LandingPageMiddleSection", element: <LPMiddleSection /> },
    { path: "letterSize", element: <LetterSize /> },
    { path: "ProductList", element: <ProductList /> },
    { path: "orderlist", element: <Order /> },
    { path: "color", element: <Color /> },
    { path: "types", element: <Types /> },
    { path: "typeDetail", element: <TypeDetail /> },
    { path: "stitchingtype", element: <StitchingType /> },
    { path: "embroidery", element: <Embroidery /> },
  ],
};

export default MainRoutes;
