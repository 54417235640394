import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  FormLabel,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import axios from "../../utils/axios";
import { useGet, useDelete } from "../../hooks/useFetch";
import {
  SUB_CATEGORY_API,
  GET,
  POST,
  PUT,
  DELETE,
  CATEGORY_API,
  TYPES_API,
} from "../../api/config";
import NotificationContext from "../../store/notification-context";
import ImagePicker from "../ui/image-picker";
import { appendImage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import InfoIcon from '@mui/icons-material/Info';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SubCategory() {
  const [cValue, setCValue] = useState("");
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [typeIds, setTypeIds] = useState([]);

  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    SUB_CATEGORY_API[GET],
    {
      apiCall: "onload",
    }
  );

  const { data: getTypeData } = useGet(TYPES_API[GET], {
    apiCall: "onload",
  });
  const { data: getCategoryData } = useGet(CATEGORY_API[GET], {
    apiCall: "onload",
  });
  const { refresh: delData } = useDelete(SUB_CATEGORY_API[DELETE]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setSubCategories(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    if (getTypeData) {
      setTypes(getTypeData.data || []);
    }
  }, [getTypeData]);

  useEffect(() => {
    if (getCategoryData) {
      setCategories(getCategoryData.data || []);
    }
  }, [getCategoryData]);

  const typesHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTypeIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const columns = [
    { field: "name", headerName: "Name", width: 250, sortable: true },
    {
      field: "priority",
      headerName: "Priority",
      width: 200,
      sortable: true,
    },
    {
      field: "image",
      headerName: "Image",
      width: 250,
      sortable: true,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 250,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update SubCategory";
    } else if (action === "add") {
      return "Add SubCategory";
    } else {
      return "Delete SubCategory";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const editRowData = (params) => {
    const { name, priority, image, categoryId, typeId } = params.row;
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setValue("name", name);
    setValue("priority", priority);
    setImage(image);
    setCValue(categoryId);
    setTypeIds(typeId);
  };

  const categoryHandleChange = (event) => {
    setCValue(event.target.value);
  };

  const onSubmit = (data) => {
    if (action === "add") {
      const image = data.image[0];
      const formData = new FormData();
      formData.append("image", image);
      formData.append("priority", data.priority);
      formData.append("name", data.name);
      formData.append("categoryId", cValue);
      typeIds.forEach((type) => {
        formData.append("typeId", type);
      });
      axios({
        async: true,
        url: SUB_CATEGORY_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      })
        .then((response) => {
          closeModal();
          refreshGetData();
          notificationCtx.showNotification({
            title: response.data.message,
            status: "success",
          });
        })
        .catch(function (error) {
          console.log(error.response.status); // 401
          console.log(error.response.data.error); //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            //redirect to login
            notificationCtx.showNotification({
              title: "Access Denied",
              status: "error",
            });
            setTimeout(() => {
              navigate("/dashboard");
              notificationCtx.hideNotification();
            }, 1000);
            return;
          }
        });
    } else {
      const formData = new FormData();
      let image;
      if (typeof data.image === "object" && data.image.length > 0) {
        image = data.image[0];
        formData.append("image", image);
      }
      formData.append("priority", data.priority);
      formData.append("name", data.name);
      formData.append("categoryId", cValue);
      formData.append("_id", rowData._id);
      typeIds.forEach((type) => {
        formData.append("typeId", type);
      });
      axios({
        async: true,
        url: SUB_CATEGORY_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      })
        .then((response) => {
          closeModal();
          refreshGetData();
          notificationCtx.showNotification({
            title: response.data.message,
            status: "success",
          });
        })
        .catch(function (error) {
          console.log(error.response.status); // 401
          console.log(error.response.data.error); //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            //redirect to login
            notificationCtx.showNotification({
              title: "Access Denied",
              status: "error",
            });
            setTimeout(() => {
              navigate("/dashboard");
              notificationCtx.hideNotification();
            }, 1000);
            return;
          }
        });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Manage SubCategories"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    reset();
                  }}
                >
                  Add SubCategory
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={subCategories}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={125}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="select-category">
                        Select Category
                      </InputLabel>
                      <Select
                        labelId="select-category"
                        id="select-category"
                        value={cValue}
                        label="Category"
                        onChange={categoryHandleChange}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Types
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={typeIds}
                      onChange={typesHandleChange}
                      input={<OutlinedInput label="Types" />}
                      renderValue={(selected) =>
                        selected.map((select) => (
                          <Chip
                            key={select}
                            label={types?.find((e) => e._id === select).name}
                          />
                        ))
                      }
                      MenuProps={MenuProps}
                    >
                      {types.map((type) => {
                        return (
                          <MenuItem key={type._id} value={type._id}>
                            <Checkbox checked={typeIds.includes(type._id)} />
                            <ListItemText primary={type.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Priority"
                    placeholder="Enter Priority"
                    {...register("priority", { required: true })}
                  />
                  {errors.priority && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 1200×1406 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default SubCategory;
